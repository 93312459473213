import React, { useState, useEffect } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import '../photos/UploadNewPhoto.css'
import { db, auth } from '../../firebase'

function AddUser() {

    const [error, setError] = useState('')
    const { currentUser, logout } = useAuth()
    const history = useHistory()
    
    const [userFirstName, setUserFirstName] = useState('')
    const [userLastName, setUserLastName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userPassword, setUserPassword] = useState('')
    const [userVerifyPassword, setUserVerifyPassword] = useState('')
    const [userRole, setUserRole] = useState('')

    const [uploading, setUploading] = useState(false);
    const [connecting, setConnecting] = useState(true);
    const [cannotConnect, setCannotConnect] = useState(false);

    // loaded from database state

    const [allUsers, setAllUsers] = useState([])

    // might need some kind of API for the shipping, tax and base prices w/o markup

    async function handleLogout() {
        setError("")

        try {
            await logout()
            history.push("/admin/login")
        } catch {
            setError("Failed to logout")
        }
    }

    // get all Users
    useEffect(() => {
        // setConnecting(true)

        const allUsersSubscriber = db
            .collection('users')
            .onSnapshot(snapshot => {
                setAllUsers(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
                if (allUsers !== undefined) {
                    setConnecting(false)
                    // this is happenning anyway when no internet
                } else {
                    setTimeout(() => {
                        setConnecting(false);
                        setCannotConnect(true);
                    }, 2000)
                }
            })

        // cleanup function
        return () => allUsersSubscriber()
        
    }, [allUsers])


    // check if name already exists in database
    // eslint-disable-next-line
    const duplicateUserCheck = (entered_email) => {
        let value = false

        for (let user_index = 0; user_index < allUsers.length; user_index++) {
            if (allUsers[user_index].data.email === entered_email) {
                alert('A User with this email already exists, please check your records.')
                value = true
            }
        }

        return value
    }

    // eslint-disable-next-line
    const verifyPassword = (password) => {
        // this will need to make sure of special characters and numbers etc...
        return
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        setUploading(true)

        // add User
        if (userPassword === userVerifyPassword){

            auth.createUserWithEmailAndPassword(userEmail, userPassword)
            .then((user) => {
                db
                    .collection('users')
                    .doc(user.uid) // need to test if this works!!!!!
                    .set({
                        user_name: userLastName + ', ' + userFirstName,
                        user_email: userEmail,
                        user_role: userRole,
                    }).then(() => {
                        // reset form w/ refresh
                        window.location.reload()
                    })
                    // after this happens, I need to send an email to the User with a link to the login page
                    // and their temporary password. They should also get instructions on how to reset their password.
            })
        }
    }


    return (
        <div className="upload-new-div">
            <div id="uploading_message" style={{'display': uploading ? 'block' : 'none'}}>
                <h1>Uploading...</h1>
            </div>
            <div id="cannot_connect" style={{'display': cannotConnect ? 'block' : 'none'}} >
                <div>
                    <h1>We are sorry, but there seems to be an error with your connection...</h1>
                    <p>Make sure you are connected to the internet and refresh the page.</p>
                </div>
            </div> 
            <div id="connecting_message" style={{'display': connecting ? 'block' : 'none'}}>
                <h1>Connecting to Firebase...</h1>
            </div>
            <div id="logged_email">
                <div id="email_admin"><strong>Email: </strong>{currentUser.email}</div>
                <button onClick={handleLogout}>Log out</button>
                {error}
            </div>
            <h1>Create New User Account</h1>
            <p>back to <a href="/admin/update_site_info">dashboard</a></p>
            <form id="submit-photo" onSubmit={handleSubmit}>
                <label>User First Name:</label>
                <input type="text" value={userFirstName} onChange={e => setUserFirstName(e.target.value)} />
                <label>User Last Name:</label>
                <input type="text" value={userLastName} onChange={e => setUserLastName(e.target.value)} />
                <label>User Email:</label>
                <input type="text" value={userEmail} onChange={e => setUserEmail(e.target.value)} />
                <label>User Role:</label>
                <br />
                <select onChange={e => setUserRole(e.target.value)}>
                    <option value="owner">Owner</option>
                    <option value="admin">Admin</option>
                    <option value="user">Client</option>
                </select>
                <br />
                <label>Temporary Password:</label>
                <input type="text" value={userPassword} onChange={e => setUserPassword(e.target.value)} />
                <label>Temporary Password (again):</label>
                <input type="text" value={userVerifyPassword} onChange={e => setUserVerifyPassword(e.target.value)} />
                <input type="submit" value="add User" />
            </form>
        </div>
    )
}

export default AddUser
