import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'


export default function PrivateRouteOwner({ component: Component, ...rest }) {

    const { currentUser, userRole } = useAuth()

    return (
        <Route
        {...rest}
        render={props => {
            return (userRole === 'owner' && currentUser) ? <Component {...props} /> : <Redirect to="/admin/login" />
        }}>
            
        </Route>
    )
}