import React, { useState } from 'react';
import './WeddingPackages.css';
import GenericForm from '../GenericForm.js';

function WeddingPackages() {

    const [sonataVisible, setSonataVisible] = useState(false);
    const [concertoVisible, setConcertoVisible] = useState(false);
    const [symphonyVisible, setSymphonyVisible] = useState(false);

    const Sonata_Package_Button = () => {
        setSonataVisible(true);
    }

    const Concerto_Package_Button = () => {
        setConcertoVisible(true);
    }

    const Symphony_Package_Button = () => {
        setSymphonyVisible(true);
    }

    const RemoveModal = () => {
        setSonataVisible(false);
        setConcertoVisible(false);
        setSymphonyVisible(false);
    }

    return (
        <div id="wedding-packages-component">
            {sonataVisible ? <div id="portrait-request-form">
                <div id="main-form-div">
                <GenericForm packageName={<h2><strong>SONATA</strong> Package</h2>} />
                </div>
                <div onClick={RemoveModal} id="modal-background"></div>
            </div> : null}
            {concertoVisible ? <div id="portrait-request-form">
                <div id="main-form-div">
                <GenericForm packageName={<h2><strong>CONCERTO</strong> Package</h2>} />
                </div>
                <div onClick={RemoveModal} id="modal-background"></div>
            </div> : null}
            {symphonyVisible ? <div id="portrait-request-form">
                <div id="main-form-div">
                <GenericForm packageName={<h2><strong>SYMPHONY</strong> Package</h2>} />
                </div>
                <div onClick={RemoveModal} id="modal-background"></div>
            </div> : null}
            <div id="wedding-packages-header">
                <h1>Wedding Packages</h1>
                <p id="portrait-packages-description">select a package to request a consultation</p>
            </div>
            <div id="wedding-packages">
                <div id="wedding-package-one">
                    <h2><strong>SONATA</strong> Package</h2>
                    <div>
                        <ul className="solo-package-list">
                            <li>Google Drive gallery for purchasing</li>
                            <li>1 complimentary print</li>
                            <li>15 retouched digital photos</li>
                            <li>1.5 hour session</li>
                            <li>photo release form</li>
                            <p><span style={{'fontWeight': 500}}>best for: </span><br />Ceremony only</p>
                        </ul>
                    </div>
                    <h1 style={{'fontWeight': 500}}>$750</h1>
                    <button className="select-button" onClick={Sonata_Package_Button}>Select</button>
                </div>
                <div id="wedding-package-two">
                    <h2><strong>CONCERTO</strong> Package</h2>
                    <div>
                        <ul className="solo-package-list">
                            <li>Google Drive gallery for purchasing</li>
                            <li>2 complimentary prints</li>
                            <li>25 retouched digital photos</li>
                            <li>3 hour session</li>
                            <li>photo release form</li>
                            <p><span style={{'fontWeight': 500}}>best for: </span><br />Ceremony and portraits</p>
                        </ul>
                    </div>
                    <h1 style={{'fontWeight': 500}}>$1,000</h1>
                    <button className="select-button" onClick={Concerto_Package_Button}>Select</button>
                </div>
                <div id="wedding-package-three">
                    <h2><strong>SYMPHONY</strong> Package</h2>
                    <div>
                        <ul className="solo-package-list">
                            <li>Google Drive gallery for purchasing</li>
                            <li>2 complimentary prints</li>
                            <li>full gallery of digital photos</li>
                            <li>8 hour session</li>
                            <li>photo release form</li>
                            <p><span style={{'fontWeight': 500}}>best for: </span><br />All day coverage with portraits</p>
                        </ul>
                    </div>
                    <h1 style={{'fontWeight': 500}}>$2,500</h1>
                    <button className="select-button" onClick={Symphony_Package_Button}>Select</button>
                </div>
            </div>
            <p id="overview-link-wedding">back to <a href="/hire_me">overview</a></p>
        </div>
    )
}

export default WeddingPackages
