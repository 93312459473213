import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import '../photos/UploadNewPhoto.css'
import { db } from '../../firebase'

function UploadNewItem() {

    const [error, setError] = useState('')
    const { currentUser, logout } = useAuth()
    const history = useHistory()

    async function handleLogout() {
        setError("")

        try {
            await logout()
            history.push("/admin/login")
        } catch {
            setError("Failed to logout")
        }
    }

    const makeFeaturedItemMug = (e) => {
        e.preventDefault();

        db
        .collection('items')
        .doc('selected_featured_item')
        .set({
            item: 'Mug'
        }).then(() => {
            alert('Featured Item set to: Mug')
        })
    }

    const makeFeaturedItemPillowCase = (e) => {
        e.preventDefault();

        db
        .collection('items')
        .doc('selected_featured_item')
        .set({
            item: 'Pillow Case'
        }).then(() => {
            alert('Featured Item set to: Pillow Case')
        })
    }

    const makeFeaturedItemBlanket = (e) => {
        e.preventDefault();

        db
        .collection('items')
        .doc('selected_featured_item')
        .set({
            item: 'Blanket'
        }).then(() => {
            alert('Featured Item set to: Blanket')
        })
    }

    const makeFeaturedItemCalendar = (e) => {
        e.preventDefault();

        db
        .collection('items')
        .doc('selected_featured_item')
        .set({
            item: 'Calendar'
        }).then(() => {
            alert('Featured Item set to: Calendar')
        })
    }

    return (
        <div className='upload-new-div'>
            <div id="logged_email">
                <div id="email_admin"><strong>Email: </strong>{currentUser.email}</div>
                <button onClick={handleLogout}>Log out</button>
                {error}
            </div>
            <h1>Choose item to upload</h1>
            <p>back to <a href="/admin/update_site_info">dashboard</a></p>
            <div id="choose-item">
                <button className='upload-item-button' onClick={makeFeaturedItemMug}>Mug</button>
                <button className='upload-item-button' onClick={makeFeaturedItemPillowCase}>Pillow Case</button>
                <button className='upload-item-button' onClick={makeFeaturedItemBlanket}>Blanket</button>
                <button className='upload-item-button' onClick={makeFeaturedItemCalendar}>Calendar</button>
            </div>
        </div>
    )
}

export default UploadNewItem