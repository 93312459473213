import React, { useState } from 'react';
import './EventPackages.css';
import GenericForm from '../GenericForm.js';

function EventPackages() {

    const [ariaVisible, setAriaVisible] = useState(false);
    const [recitativeVisible, setRecitativeVisible] = useState(false);
    const [overtureVisible, setOvertureVisible] = useState(false);

    const Aria_Package_Button = () => {
        setAriaVisible(true);
    }

    const Recitative_Package_Button = () => {
        setRecitativeVisible(true);
    }

    const Overture_Package_Button = () => {
        setOvertureVisible(true);
    }

    const RemoveModal = () => {
        setAriaVisible(false);
        setRecitativeVisible(false);
        setOvertureVisible(false);
    }
    
    return (
        <div id="event-packages-component">
            {ariaVisible ? <div id="portrait-request-form">
                <div id="main-form-div">
                <GenericForm packageName={<h2><strong>ARIA</strong> Package</h2>} />
                </div>
                <div onClick={RemoveModal} id="modal-background"></div>
            </div> : null}
            {recitativeVisible ? <div id="portrait-request-form">
                <div id="main-form-div">
                <GenericForm packageName={<h2><strong>RECITATIVE</strong> Package</h2>} />
                </div>
                <div onClick={RemoveModal} id="modal-background"></div>
            </div> : null}
            {overtureVisible ? <div id="portrait-request-form">
                <div id="main-form-div">
                <GenericForm packageName={<h2><strong>OVERTURE</strong> Package</h2>} />
                </div>
                <div onClick={RemoveModal} id="modal-background"></div>
            </div> : null}
            <div id="event-packages-header">
                <h1>Event Packages</h1>
                <p id="portrait-packages-description">select a package to request a consultation</p>
            </div>
            <div id="event-packages">
                <div id="event-package-one">
                    <h2><strong>ARIA</strong> Package</h2>
                    <div>
                        <ul className="solo-package-list">
                            <li>Google Drive gallery for purchasing</li>
                            <li>1 complimentary print</li>
                            <li>10 retouched digital photos</li>
                            <li>1 hour session</li>
                            <li>photo release form</li>
                            <p><span style={{'fontWeight': 500}}>best for: </span><br />Graduations/Recitals</p>
                        </ul>
                    </div>
                    <h1 style={{'fontWeight': 500}}>$500</h1>
                    <button className="select-button" onClick={Aria_Package_Button}>Select</button>
                </div>
                <div id="event-package-two">
                    <h2><strong>RECITATIVE</strong> Package</h2>
                    <div>
                        <ul className="solo-package-list">
                            <li>Google Drive gallery for purchasing</li>
                            <li>2 complimentary prints</li>
                            <li>25 retouched digital photos</li>
                            <li>3 hour session</li>
                            <li>photo release form</li>
                            <p><span style={{'fontWeight': 500}}>best for: </span><br />Parties/Baby Showers</p>
                        </ul>
                    </div>
                    <h1 style={{'fontWeight': 500}}>$750</h1>
                    <button className="select-button" onClick={Recitative_Package_Button}>Select</button>
                </div>
                <div id="event-package-three">
                    <h2><strong>OVERTURE</strong> Package</h2>
                    <div>
                        <ul className="solo-package-list">
                            <li>Google Drive gallery for purchasing</li>
                            <li>2 complimentary prints</li>
                            <li>full gallery of digital photos</li>
                            <li>session lasts for the entire duration of the event</li>
                            <li>photo release form</li>
                            <p><span style={{'fontWeight': 500}}>best for: </span><br />Concerts/Business Events</p>
                        </ul>
                    </div>
                    <h1 style={{'fontWeight': 500}}>$1,200</h1>
                    <button className="select-button" onClick={Overture_Package_Button}>Select</button>
                </div>
            </div>
            <p id="overview-link-event">back to <a href="/hire_me">overview</a></p>
        </div>
    )
}

export default EventPackages
