import React, { useState, useEffect } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import './UploadNewPhoto.css'
import { db } from '../../firebase'

function EditPhoto() {

    const [error, setError] = useState('')
    const { currentUser, logout } = useAuth()
    const history = useHistory()
    
    const [name, setName] = useState('')
    const [newName, setNewName] = useState('')
    const [description, setDescription] = useState('')
    const [materialOne, setMaterialOne] = useState('')
    const [materialTwo, setMaterialTwo] = useState('')
    const [materialThree, setMaterialThree] = useState('')
    const [allRatios, setAllRatios] = useState([])
    const [aspectRatio, setAspectRatio] = useState('')
    const [percentMarkup, setPercentMarkup] = useState(0)
    const [photoLocation, setPhotoLocation] = useState('')

    const [photo, setPhoto] = useState(null)

    const [uploading, setUploading] = useState(false);
    const [connecting, setConnecting] = useState(true);
    const [cannotConnect, setCannotConnect] = useState(false);

    // loaded from database state
    const [materialSet, setMaterialSet] = useState([])

    const [fineArtPhotos, setFineArtPhotos] = useState([])
    const [earlyBirdPhotos, setEarlyBirdPhotos] = useState([])
    const [storagePhotos, setStoragePhotos] = useState([])

    const [photoNames, setPhotoNames] = useState([])

    // used for deleting photos when location is changed
    const [originalLocation, setOriginalLocation] = useState('')
    const [areYouSureBox, setAreYouSureBox] = useState(false)

    // might need some kind of API for the shipping, tax and base prices w/o markup

    async function handleLogout() {
        setError("")

        try {
            await logout()
            history.push("/admin/login")
        } catch {
            setError("Failed to logout")
        }
    }

    // get ratios
    useEffect(() => {

        const subscriber = db
            .collection('aspect_ratios')
            .doc('ratios')
            .onSnapshot(snapshot => {
                setAllRatios(snapshot.data());
            })

        // cleanup function
        return () => subscriber()
        
    }, []) // empty array means this only runs once

    // get Fine Art Photos
    useEffect(() => {

        const fineArtSubscriber = db
            .collection('photos')
            .doc('photos')
            .collection('Fine Art')
            .onSnapshot(snapshot => {
                setFineArtPhotos(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            })

        // cleanup function
        return () => fineArtSubscriber()
        
    }, []) // empty array means this only runs once

    // get Early Bird Photos
    useEffect(() => {

        const earlyBirdSubscriber = db
            .collection('photos')
            .doc('photos')
            .collection('Early Bird')
            .onSnapshot(snapshot => {
                setEarlyBirdPhotos(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            })

        // cleanup function
        return () => earlyBirdSubscriber()
        
    }, []) // empty array means this only runs once

    // get Storage Photos
    useEffect(() => {

        const storageSubscriber = db
            .collection('photos')
            .doc('photos')
            .collection('Storage')
            .onSnapshot(snapshot => {
                setStoragePhotos(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
                if (allRatios !== undefined) {
                    setConnecting(false)
                } else {
                    setTimeout(() => {
                        setConnecting(false);
                        setCannotConnect(true);
                    }, 2000)
                }
            })

        // cleanup function
        return () => storageSubscriber()
        
    }, [allRatios]) // empty array means this only runs once
    
    // separate use effect for populating the options
    useEffect(() => {
        
        if (!connecting) {
            if (allRatios !== undefined) {
                let photos = []
                const ratioSetTemp = new Set([])
                const sizeSetTemp = new Set([])
                const materialSetTemp = new Set([])
                for (let ratio in allRatios) {
                    ratioSetTemp.add(ratio)
                    for (let size in allRatios[ratio]) {
                        sizeSetTemp.add(size)
                        for (let material in allRatios[ratio][size]) {
                            materialSetTemp.add(material)
                        }
                    }
                }
            
                setMaterialSet(Array.from(materialSetTemp))
    
                for (let docs in fineArtPhotos) {
                    photos.push(fineArtPhotos[docs].id)
                }
                for (let docs in earlyBirdPhotos) {
                    photos.push(earlyBirdPhotos[docs].id)
                }
                for (let docs in storagePhotos) {
                    photos.push(storagePhotos[docs].id)
                }
    
                setPhotoNames(photos)
            }
        }

    }, [connecting, allRatios, earlyBirdPhotos, fineArtPhotos, storagePhotos])
    // may need to remove some of these in order to choose materials


    const handleSubmit = (e) => {
        e.preventDefault();
        
        // delete duplicates if moved...

        if (originalLocation !== photoLocation || name !== newName) {

            db
                .collection('photos')
                .doc('photos')
                .collection(originalLocation)
                .doc(name)
                .delete()

        }

        // add to database
        setUploading(true);

        db
        .collection('photos')
        .doc('photos')
        .collection(photoLocation)
        .doc(newName)
        .set({
            photo_name: newName,
            description: description,
            material_one: materialOne,
            material_two: materialTwo,
            material_three: materialThree,
            aspectRatio: aspectRatio,
            mark_up: percentMarkup,
            download_url: photo
        }).then(() => {
            // reset form w/ refresh
            window.location.reload()
        })

    }

    const handleDelete = (e) => {
        e.preventDefault();

        setAreYouSureBox(true)
    }

    const areYouSureCancel = (e) => {
        e.preventDefault();

        setAreYouSureBox(false)
    }
    
    const areYouSureConfirm = (e) => {
        e.preventDefault();

        db
            .collection('photos')
            .doc('photos')
            .collection(originalLocation)
            .doc(name)
            .delete()

        setAreYouSureBox(true)
    }

    const changeValsForName = (e) => {

        setName(e.target.value)

        const all_options = document.getElementsByTagName('option')
        for (let option of all_options) {
            option.removeAttribute('selected')
        }

        for (let docs in fineArtPhotos) {
            const material_one_option = document.getElementById(fineArtPhotos[docs].data.material_one + 'one')
            const material_two_option = document.getElementById(fineArtPhotos[docs].data.material_two + 'two')
            const material_three_option = document.getElementById(fineArtPhotos[docs].data.material_three + 'three')
            const location_option = document.getElementById("Fine Art")
            const photo_preview = document.getElementById("photo-preview")
            if (e.target.value === fineArtPhotos[docs].id) {
                photo_preview.setAttribute('src', fineArtPhotos[docs].data.download_url)
                setNewName(fineArtPhotos[docs].data.photo_name)
                setDescription(fineArtPhotos[docs].data.description)
                setPhoto(fineArtPhotos[docs].data.download_url)
                setAspectRatio(fineArtPhotos[docs].data.aspectRatio)
                setMaterialOne(fineArtPhotos[docs].data.material_one)
                material_one_option.setAttribute('selected', 'selected')
                // material_one_option.removeAttribute('selected', 'selected')
                setMaterialTwo(fineArtPhotos[docs].data.material_two)
                material_two_option.setAttribute('selected', 'selected')
                // material_two_option.removeAttribute('selected', 'selected')
                setMaterialThree(fineArtPhotos[docs].data.material_three)
                material_three_option.setAttribute('selected', 'selected')
                // material_three_option.removeAttribute('selected', 'selected')
                setPercentMarkup(fineArtPhotos[docs].data.mark_up)
                setPhotoLocation("Fine Art")
                location_option.setAttribute('selected', 'selected')
                // location_option.removeAttribute('selected', 'selected')
                setOriginalLocation("Fine Art")
            }
        }

        for (let docs in earlyBirdPhotos) {
            const material_one_option = document.getElementById(earlyBirdPhotos[docs].data.material_one + 'one')
            const material_two_option = document.getElementById(earlyBirdPhotos[docs].data.material_two + 'two')
            const material_three_option = document.getElementById(earlyBirdPhotos[docs].data.material_three + 'three')
            const location_option = document.getElementById("Early Bird")
            const photo_preview = document.getElementById("photo-preview")
            if (e.target.value === earlyBirdPhotos[docs].id) {
                photo_preview.setAttribute('src', earlyBirdPhotos[docs].data.download_url)
                setNewName(earlyBirdPhotos[docs].data.photo_name)
                setDescription(earlyBirdPhotos[docs].data.description)
                setPhoto(earlyBirdPhotos[docs].data.download_url)
                setAspectRatio(earlyBirdPhotos[docs].data.aspectRatio)
                setMaterialOne(earlyBirdPhotos[docs].data.material_one)
                material_one_option.setAttribute('selected', 'selected')
                // material_one_option.removeAttribute('selected', 'selected')
                setMaterialTwo(earlyBirdPhotos[docs].data.material_two)
                material_two_option.setAttribute('selected', 'selected')
                // material_two_option.removeAttribute('selected', 'selected')
                setMaterialThree(earlyBirdPhotos[docs].data.material_three)
                material_three_option.setAttribute('selected', 'selected')
                // material_three_option.removeAttribute('selected', 'selected')
                setPercentMarkup(earlyBirdPhotos[docs].data.mark_up)
                setPhotoLocation("Early Bird")
                location_option.setAttribute('selected', 'selected')
                // location_option.removeAttribute('selected', 'selected')
                setOriginalLocation("Early Bird")
            }
        }

        for (let docs in storagePhotos) {
            const material_one_option = document.getElementById(storagePhotos[docs].data.material_one + 'one')
            const material_two_option = document.getElementById(storagePhotos[docs].data.material_two + 'two')
            const material_three_option = document.getElementById(storagePhotos[docs].data.material_three + 'three')
            const location_option = document.getElementById("Storage")
            const photo_preview = document.getElementById("photo-preview")
            if (e.target.value === storagePhotos[docs].id) {
                photo_preview.setAttribute('src', storagePhotos[docs].data.download_url)
                setNewName(storagePhotos[docs].data.photo_name)
                setDescription(storagePhotos[docs].data.description)
                setPhoto(storagePhotos[docs].data.download_url)
                setAspectRatio(storagePhotos[docs].data.aspectRatio)
                setMaterialOne(storagePhotos[docs].data.material_one)
                material_one_option.setAttribute('selected', 'selected')
                // material_one_option.removeAttribute('selected', 'selected')
                setMaterialTwo(storagePhotos[docs].data.material_two)
                material_two_option.setAttribute('selected', 'selected')
                // material_two_option.removeAttribute('selected', 'selected')
                setMaterialThree(storagePhotos[docs].data.material_three)
                material_three_option.setAttribute('selected', 'selected')
                // material_three_option.removeAttribute('selected', 'selected')
                setPercentMarkup(storagePhotos[docs].data.mark_up)
                setPhotoLocation("Storage")
                location_option.setAttribute('selected', 'selected')
                // location_option.removeAttribute('selected', 'selected')
                setOriginalLocation("Storage")
            }
        }
    }

    return (
        <div className="upload-new-div">
            <div id="are_you_sure_box" style={{'display': areYouSureBox ? 'block' : 'none'}}>
                Are you sure you would like to delete this item? <br />
                This action cannot be undone. <br />
                <div id="are_you_sure_box_options">
                    <button onClick={areYouSureConfirm}>yes, I'm sure</button>
                    <button onClick={areYouSureCancel}>cancel</button>
                </div>
            </div>
            <div id="uploading_message" style={{'display': uploading ? 'block' : 'none'}}>
                <h1>Uploading...</h1>
            </div>
            <div id="cannot_connect" style={{'display': cannotConnect ? 'block' : 'none'}} >
                <div>
                    <h1>We are sorry, but there seems to be an error with your connection...</h1>
                    <p>Make sure you are connected to the internet and refresh the page.</p>
                </div>
            </div>
            <div id="connecting_message" style={{'display': connecting ? 'block' : 'none'}}>
                <h1>Connecting...</h1>
            </div>
            <div id="logged_email">
                <div id="email_admin"><strong>Email: </strong>{currentUser.email}</div>
                <button onClick={handleLogout}>Log out</button>
                {error}
            </div>
            <h1>Edit Existing Photo info</h1>
            <p>back to <a href="/admin/update_site_info">dashboard</a></p>
            <form id="submit-photo" onSubmit={handleSubmit}>
                <label>Photo Name:</label>
                <br />
                <select name="photo_name" defaultValue="choose photo" onChange={changeValsForName} >
                    <option disabled>choose photo</option>
                    {photoNames.map(item => {
                        return (<option id={item} key={item} value={item}>{item}</option>)
                    })}
                </select>
                <button disabled={name !== '' ? false : true} onClick={handleDelete}>delete photo</button>
                <br />
                <label>New Name:</label>
                <br />
                <input type="text" value={newName} onChange={e => setNewName(e.target.value)} />
                <br />
                <label>Photo Description:</label>
                <br />
                <textarea type="text" value={description} onChange={e => setDescription(e.target.value)} />
                <br />
                <label>Photo Material 1:</label>
                <br />
                <select name="material_one" defaultValue="choose material" onChange={e => setMaterialOne(e.target.value)} >
                    <option disabled>choose material</option>
                    {materialSet.map(item => {
                        return (<option id={item + 'one'} key={item} value={item}>{item}</option>)
                    })}
                </select>
                <br />
                <label>Photo Material 2:</label>
                <br />
                <select name="material_two" defaultValue="choose material" onChange={e => setMaterialTwo(e.target.value)} >
                    <option disabled>choose material</option>
                    {materialSet.map(item => {
                        return (<option id={item + 'two'} key={item} value={item}>{item}</option>)
                    })}
                </select>
                <br />
                <label>Photo Material 3:</label>
                <br />
                <select name="material_three" defaultValue="choose material" onChange={e => setMaterialThree(e.target.value)} >
                    <option disabled>choose material</option>
                    {materialSet.map(item => {
                        return (<option id={item + 'three'} key={item} value={item}>{item}</option>)
                    })}
                </select>
                <br />
                <label>Percent Mark Up:</label>
                <input id="percent_markup" type="number" value={percentMarkup} onChange={e => setPercentMarkup(e.target.value)} />
                <label>Location:</label>
                <br />
                <select name="location" defaultValue="choose location" onChange={e => setPhotoLocation(e.target.value)} >
                    <option disabled>choose location</option>
                    <option id="Fine Art">Fine Art</option>
                    <option id="Early Bird">Early Bird</option>
                    {/* adding to early bird will push to front of an array in order to prevent confusion
                    and early bird page will only display first of array */}
                    <option id="Storage">Storage</option>
                </select>
                <br />
                <input type="submit" value="submit changes" />
            </form>
            <img alt="preview of uploaded file" id='photo-preview' src="" />
        </div>
    )
}

export default EditPhoto
