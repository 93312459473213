import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'


export default function PrivateRouteClient({ component: Component, ...rest }) {

    const { currentUser, userRole } = useAuth()

    return (
        <Route
        {...rest}
        render={props => {
            return userRole === 'client' && currentUser ? <Component {...props} /> : <Redirect to="/client/login" />
        }}>
            
        </Route>
    )
}