import {React, useState} from 'react';
import './Subtotal.css';
import CurrencyFormat from 'react-currency-format';
import { useStateValue } from './StateProvider';
import { getSubTotal } from './reducer';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


function Subtotal() {

    const history = useHistory();
    const [{ basket }] = useStateValue();

    // change this back to '' when copyright contract is back
    // eslint-disable-next-line
    const [checked, setChecked] = useState(true);

    const proceedToPayment = (e) => {        
        if (checked === true) {
            history.push('/payment');
        } else if (basket.length === 0) {
            alert('You must add items to the cart first!')
        } else if (checked === false) {
            alert('You must read and accept the copyright contract before proceeding to checkout.');
        }
    }

    return (
        <div className="subtotal" >
            <CurrencyFormat
                renderText={(value) => (
                    <>
                        <span>
                            Subtotal ({basket.length} items): 
                            <strong> {value}</strong>
                        </span>
                        {/* <br/>
                        <small className="subtotal-gift">
                            <input onChange={e => setChecked(e.target.checked)} id='accept' type="checkbox" />I have read and accept the:
                        </small>
                        <small>
                            <a id="contract" href="/copyrightcontract">copyright contract</a>
                        </small> */}
                    </>    
                )}
                decimalScale={2}
                fixedDecimalScale={true}
                value={getSubTotal(basket)}
                displayType={"text"} 
                thousandSeparator={true}
                prefix={"$"}
            /> 

            <button onClick={proceedToPayment} >Proceed to Checkout</button> 
              
        </div>
    )
}

export default Subtotal
