import React, { useState } from 'react';
import './AdminLogin.css'
import { useAuth } from '../../contexts/AuthContext';
import { useHistory } from 'react-router-dom'

function AdminLogin() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const { login } = useAuth()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError("")
            setLoading(true)
            await login(email, password)
            history.push("/admin/update_site_info") // currently the page does not stop from redirecting if the role permissions are incorrect
            // it only prevents the content from loading on the page
            // because of this, I cannot set the error message since it redirects back and forth from both pages.
            // refer to AuthContext.js for code
        } catch {
            setError("Failed to log in")
        }

        // setLoading(false)
    }

    return (
        <div id="login">
            <h1>Log In</h1>
            <form onSubmit={handleSubmit}>
                <label>Username:</label>
                <input type='email' onChange={e => setEmail(e.target.value)} required />
                <label>Password:</label>
                <input type='password' onChange={e => setPassword(e.target.value)} required />
                <button disabled={loading} type="submit">Log in</button>
                {error}
            </form>
        </div>
    )
}

export default AdminLogin
