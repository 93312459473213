import React, { useState } from "react";
import './UpdateSiteInfo.css'
import { useAuth } from '../../contexts/AuthContext'
import { useHistory, Link } from 'react-router-dom'


function UploadPhotoInfo() {
    const [error, setError] = useState('')
    const { currentUser, logout } = useAuth()
    const history = useHistory()

    async function handleLogout() {
        setError("")

        try {
            await logout()
            history.push("/admin/login")
        } catch {
            setError("Failed to logout")
        }
    }

    return (
        <div>
            <div id="logged_email">
                <div id="email_admin"><strong>Email: </strong>{currentUser.email}</div>
                <button onClick={handleLogout}>Log out</button>
                {error}
            </div>
            <div id="upload_info_body">
                <h1>What would you like to do?</h1>
                <div id="upload_info_options">
                    <Link className="option_buttons" to="/admin/upload_photo"><button className="button-actual" >Upload a new photo</button></Link>
                    <Link className="option_buttons" to="/admin/edit_photo"><button className="button-actual">Edit existing photo info</button></Link>
                    <Link className="option_buttons" to="/admin/upload_item"><button className="button-actual">Upload a new item</button></Link>
                    <Link className="option_buttons" to="/admin/edit_item"><button className="button-actual">Edit existing item info</button></Link>
                    <Link className="option_buttons" to="/admin/add_user"><button className="button-actual">Add User</button></Link>
                    <Link className="option_buttons" to="/admin/all_orders"><button className="button-actual">All Orders</button></Link>
                    <Link className="option_buttons" to="/admin/all_inquiries"><button className="button-actual">All Inqueries</button></Link>
                    <Link className="option_buttons" to="/admin/all_requests"><button className="button-actual">All Event Requests</button></Link>
                </div>
            </div>
        </div>
    )
}

export default UploadPhotoInfo
