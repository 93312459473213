import React, { useEffect, useState } from 'react'
import './UpdateSiteInfo.css'
import Request from '../../Request';
import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import { db } from '../../firebase'

function AllRequests() {
    const [error, setError] = useState('')
    const { currentUser, logout } = useAuth()
    const history = useHistory()

    const [requests, setRequests] = useState([]);

    async function handleLogout() {
        setError("")

        try {
            await logout()
            history.push("/admin/login")
        } catch {
            setError("Failed to logout")
        }
    }

    useEffect(() => {

        db
            .collection('event-requests')
            .orderBy('created', 'desc')
            .onSnapshot(snapshot => (
                setRequests(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            ))
    }, [requests])


    return (
        <div>
            <div id="logged_email">
                <div id="email_admin"><strong>Email: </strong>{currentUser.email}</div>
                <button onClick={handleLogout}>Log out</button>
                {error}
            </div>
            <h1>All Requests</h1>
            <p>back to the <a href='/admin/update_site_info' >dashboard</a>.</p>
            <div className='orders-order'>
            {requests?.map(request => (
                <Request request={request} />
            ))}
            </div>
        </div>
    )
}

export default AllRequests
