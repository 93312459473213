import React from 'react';
import './HireMe.css';

import teacher_headshot from '../hireMe/portraits/teacher_headshot.jpg';
import beach_portrait from '../hireMe/portraits/beach_portrait.jpg';
import business_headshot from '../hireMe/portraits/business_headshot.jpg';
import mother_and_son from '../hireMe/portraits/mother_and_son.jpg';
import walking_down_pier from '../hireMe/portraits/walking_down_pier.jpg';
import recital_portrait from '../hireMe/portraits/recital_portrait.jpg';

import band_two from '../hireMe/events/band_two.jpg';
import concert_one from '../hireMe/events/concert_one.jpg';
import concert_two from '../hireMe/events/concert_two.jpg';
import graduation_party from '../hireMe/events/graduation_party.jpg';
import music_rehearsal from '../hireMe/events/music_rehearsal.jpg';
import lead_singer from '../hireMe/events/lead_singer.jpg';

import bride from '../hireMe/weddings/bride.jpg';
import cake from '../hireMe/weddings/cake.jpg';
import groom from '../hireMe/weddings/groom.jpg';
import kiss from '../hireMe/weddings/kiss.jpg';
import rings from '../hireMe/weddings/rings.jpg';
import vows from '../hireMe/weddings/vows.jpg';

function HireMe() {


    return (
        <div id="hire-me-page">
            <div id="hire-me-title">
                Hire Me
            </div>
            <div id="hire-me-description">
                <div id="my-offers">
                    {/* <hr id="hire-me-line"/> */}
                    <p id="paragraph_one">Alec Hovey Photography offers a wide variety of live photoshoot services including portrait, event and wedding shoots*. Each includes personal and intimate communication so you are sure to get exactly what you need. Each shoot also comes with access to a Google Drive folder where you will be able to select your favorite shots and download all your digital files. Alec Hovey is currently vaccinated for Covid-19, but is still willing and able to accomodate any Covid related precautions you may require.</p>
                    <br />
                    <p id="paragraph_two">*Keep scrolling for detailed descriptions and package options for each photoshoot type.</p>
                    <br />
                </div>
                <div id="portraits-section">
                    <div id="portraits">
                        <div id="portraits-title">
                            <h2 className="hire-me-section-title">Portraits/Headshots</h2>
                        </div>
                        <div id="portraits-collage">
                            <img alt="" id="portrait-photo-one" className="photo-hire-me" src={beach_portrait} />
                            <img alt="" id="portrait-photo-two" className="photo-hire-me" src={business_headshot} />
                            <img alt="" id="portrait-photo-three" className="photo-hire-me" src={mother_and_son} />
                            <img alt="" id="portrait-photo-four" className="photo-hire-me" src={recital_portrait} />
                            <img alt="" id="portrait-photo-five" className="photo-hire-me" src={teacher_headshot} />
                            <img alt="" id="portrait-photo-six" className="photo-hire-me" src={walking_down_pier} />
                        </div>
                        <div id="portraits-description">
                            <p id="paragraph_three" className="hire-me-section-description">From business to casual, formal to informal, Alec Hovey Photography will always get the best quality headshots and potraits around. His welcoming personality makes photoshoots easy and stress free and his minimalist set up makes him flexible to take photos anywhere! Alec Hovey Photography will work with you to ensure you get the exact style you are looking for by making you feel comfortable and natural in any environment.</p>
                            <span id="paragraph_four">click <a href="/hire_me/portrait_packages">here</a> for package options</span>
                        </div>
                    </div>
                </div>
                <div id="events-section">
                    <div id="events">
                        <div id="events-title">
                            <h2 className="hire-me-section-title">Events</h2>
                        </div>
                        <div id="events-collage">
                            <img alt="" id="events-photo-one" className="photo-hire-me" src={band_two} />
                            <img alt="" id="events-photo-two" className="photo-hire-me" src={concert_one} />
                            <img alt="" id="events-photo-three" className="photo-hire-me" src={concert_two} />
                            <img alt="" id="events-photo-four" className="photo-hire-me" src={music_rehearsal} />
                            <img alt="" id="events-photo-five" className="photo-hire-me" src={graduation_party} />
                            <img alt="" id="events-photo-six" className="photo-hire-me" src={lead_singer} />
                        </div>
                        <div id="events-description">
                            <p id="paragraph_three" className="hire-me-section-description">Concerts, Graduations, Parties, Sporting Events, and much more are all part of the Alec Hovey Photography repertoire. Never again feel like you have lost that special moment forever, because Alec Hovey Photography will capture it for you. No need to break out your phone or ask your friend, enjoy the moment while Alec Hovey Photography takes care of the rest. Set up a consultation and walk us through your event so we can capture every little detail you want.</p>
                            <span id="paragraph_four">click <a href="/hire_me/event_packages">here</a> for package options</span>
                        </div>
                    </div>
                </div>
                <div id="wedding-section">
                    <div id="wedding">
                        <div id="wedding-title">
                            <h2 className="hire-me-section-title">Weddings</h2>
                        </div>
                        <div id="wedding-collage">
                            <img alt="" id="wedding-photo-one" className="photo-hire-me" src={cake} />
                            <img alt="" id="wedding-photo-two" className="photo-hire-me" src={bride} />
                            <img alt="" id="wedding-photo-three" className="photo-hire-me" src={groom} />
                            <img alt="" id="wedding-photo-four" className="photo-hire-me" src={rings} />
                            <img alt="" id="wedding-photo-five" className="photo-hire-me" src={vows} />
                            <img alt="" id="wedding-photo-six" className="photo-hire-me" src={kiss} />
                        </div>
                        <div id="wedding-description">
                            <p id="paragraph_three" className="hire-me-section-description">Alec Hovey Photography knows how much your special day means to you, and we will work tirelessly to make sure every moment, every person, every laugh, and every cry is captured for your eternal memory. Planning a wedding is stressful, but capturing the moment doesn't have to be. Enjoy your stress free day and then revisit it via our expert work.</p>
                            <span id="paragraph_four">click <a href="/hire_me/wedding_packages">here</a> for package options</span>
                        </div>
                    </div>
                </div>
                <div id="photo-animation"></div>
            </div>
        </div>
    )
}

export default HireMe
