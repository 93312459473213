import React from 'react';
import './Order.css';

function Inquiry({ inquiry }) {

    return (
        <div className='order'>
            <h2 className='order-title'><strong>Inquiry</strong></h2>
            <p><small><strong>inquired on:</strong></small> {new Date(inquiry.data.created).toString()}</p>
            <p><small><strong>inquired by:</strong></small> {inquiry.data.first_name} {inquiry.data.last_name}</p>
            <p className='order-id'>
                <small><small><strong>Inquiry ID:</strong></small> {inquiry.id}</small>
            </p>
            <div className='item-description'>
               <p>{inquiry.data.inquiry}</p>
            </div>
        </div>
    )
}

export default Inquiry
