import React, { useState } from 'react';
import './Header.css';
import { BiBasket, BiMenu } from 'react-icons/bi';
import { useStateValue } from './StateProvider';
import { Link } from 'react-router-dom';
// import { useState } from 'react';

function Header({visibility, height, padding}) {

    const [{ basket }] = useStateValue();
    // const localData = localStorage.getItem('cart');
    // const cartItems = JSON.parse(localData);

    const [sideMenuActive, setSideMenuActive] = useState(true);

    const headerStyle = {
        visibility: visibility, 
        height: height, 
        padding: padding
    }

    window.addEventListener("scroll", () => {
        var navBar = document.querySelector("#nav-links");
        var fixedNav = document.querySelector("#nav-links-fixed");
        var headerDiv = document.querySelector("#header-div");
        var headerDivFixed = document.querySelector("#header-div-fixed");
        var sideMenu = document.querySelector("#side-menu");
        var sideMenuFixed = document.querySelector('#side-menu-fixed');
        
        if (fixedNav === null || navBar === null) {
            return;
        }

        if(window.scrollY >= navBar.offsetTop) {
            fixedNav.classList.add("make-visible");
            navBar.classList.add("make-invisible");
            headerDivFixed.classList.add("make-visible");
            headerDiv.classList.add("make-invisible");
            if (sideMenu.classList.value === "make-visible") {
                sideMenu.classList.remove("make-visible");
                sideMenu.classList.add("make-invisible");
                sideMenuFixed.classList.remove("make-invisible");
                sideMenuFixed.classList.add("make-visible");
            }
        } 
        else if(window.scrollY < navBar.offsetTop) {
            fixedNav.classList.remove("make-visible");
            navBar.classList.remove("make-invisible");
            headerDivFixed.classList.remove("make-visible");
            headerDiv.classList.remove("make-invisible");
            if (sideMenuFixed.classList.value === "make-visible") {
                sideMenuFixed.classList.remove("make-visible");
                sideMenuFixed.classList.add("make-invisible");
                sideMenu.classList.remove("make-invisible");
                sideMenu.classList.add("make-visible");
            }
            // console.log(sideMenuFixed.classList.value)
        } else {
            navBar.classList.add("make-invisible");
            fixedNav.classList.add("make-invisible");
            headerDiv.classList.add("make-invisible");
            headerDivFixed.classList.add("make-invisible");
        }
    })

    window.addEventListener("resize", () => {
        var sideMenu = document.querySelector("#side-menu");
        var sideMenuFixed = document.querySelector('#side-menu-fixed');

        if(window.innerWidth > 420 && sideMenu !== null) {
            sideMenu.classList.add("make-invisible");
            sideMenu.classList.remove("make-visible");
            sideMenuFixed.classList.add("make-invisible");
            sideMenuFixed.classList.remove("make-visible");
            setSideMenuActive(true);
        }

    })

    const OpenMenu = () => {
        if (sideMenuActive === false) {
            setSideMenuActive(true);
        } else if (sideMenuActive === true) {
            setSideMenuActive(false);
        }
        // console.log(sideMenuActive);

        var sideMenu = document.querySelector("#side-menu");
        var sideMenuFixed = document.querySelector('#side-menu-fixed');
        var fixedNav = document.querySelector("#nav-links-fixed");
        var navBar = document.querySelector("#nav-links");

        if (navBar.classList.value === "" && sideMenuActive === true) {
            sideMenu.classList.add("make-visible");
            sideMenu.classList.remove("make-invisible");
        } else if (navBar.classList.value === "" && sideMenuActive === false) {
            sideMenu.classList.remove("make-visible");
            sideMenu.classList.add("make-invisible");
        } else if (fixedNav.classList.value === "make-invisible make-visible" && sideMenuActive === false) {
            sideMenuFixed.classList.add("make-invisible");
            sideMenuFixed.classList.remove("make-visible");
        } else if (fixedNav.classList.value === "make-invisible make-visible" && sideMenuActive === true) {
            sideMenuFixed.classList.remove("make-invisible");
            sideMenuFixed.classList.add("make-visible");
        }      

    }

    const minimize = () => {
        var sideMenu = document.querySelector("#side-menu");
        var sideMenuFixed = document.querySelector('#side-menu-fixed');

        sideMenu.classList.remove("make-visible");
        sideMenu.classList.add("make-invisible");
        sideMenuFixed.classList.add("make-invisible");
        sideMenuFixed.classList.remove("make-visible");
        setSideMenuActive(true);
    }

    return (
        <div id="header">
        <div id="nav-text" style={headerStyle}>
            <span id="name-text-home">
                Alec Hovey
            </span>
                <br />
            <span id="photo-text-home">
                Photography
            </span>
        </div>
        <div id="nav-links-group">
        <div id="nav-links">
            <div id="side-menu" className="make-invisible">
                <ul>
                    <li>
                        <Link onClick={minimize} className="side-menu-link" to="/featured">Featured</Link>
                    </li>
                    <br />
                    <li>
                        <Link onClick={minimize} className="side-menu-link" to="/fine_art">Fine Art</Link>
                    </li>
                    <br />
                    <li>
                        <Link onClick={minimize} className="side-menu-link" to="/hire_me">Hire me</Link>
                    </li>
                    <br />
                    <li>
                        <Link onClick={minimize} className="side-menu-link" to="/contact">Contact</Link>
                    </li>
                </ul>
            </div>
            <ul id='nav-dropdown'>
                <li>
                    <button onClick={OpenMenu} className='nav-link' id='menu-button'><BiMenu size={30} /></button>
                    {/* Click this should make a dropdown menu appear with all of the menu options */}
                </li>
            </ul> 
            <ul id="pages">
                <li>
                    <Link className="nav-link" to="/featured">Featured</Link>
                </li>
                <span>-</span>
                <li>
                    <Link className="nav-link" to="/fine_art">Fine Art</Link>
                </li>
                <span>-</span>
                <li>
                    <Link className="nav-link" to="/hire_me">Hire Me</Link>
                </li>
                <span>-</span>
                {/* <li>
                    <Link className="nav-link" to="/form">form</Link>
                </li>
                <span>-</span> */}
                <li>
                    <Link className="nav-link" to="/contact">Contact</Link>
                </li>
            </ul>
            <ul>
                <li id='cart'>
                    <Link id="basket" className="nav-link" to="/checkout"><BiBasket size={21} />{basket?.length}</Link>
                </li>
            </ul>
        </div>
        <div id="header-div"></div>
        </div>
        <div id="nav-links-fixed-group">
        <div id="nav-links-fixed" className="make-invisible">
            <div id="side-menu-fixed" className="make-invisible">
                <ul>
                    <li>
                        <Link onClick={minimize} className="side-menu-link" to="/featured">Featured</Link>
                    </li>
                    <br />
                    <li>
                        <Link onClick={minimize} className="side-menu-link" to="/fine_art">Fine Art</Link>
                    </li>
                    <br />
                    <li>
                        <Link onClick={minimize} className="side-menu-link" to="/hire_me">Hire Me</Link>
                    </li>
                    <br />
                    <li>
                        <Link onClick={minimize} className="side-menu-link" to="/contact">Contact</Link>
                    </li>
                </ul>
            </div>
            <ul id='nav-dropdown'>
                <li>
                    <button onClick={OpenMenu} className='nav-link' id='menu-button'><BiMenu size={30} /></button>
                </li>
            </ul> 
            <ul id="pages">
                <li>
                    <Link className="nav-link" to="/featured">Featured</Link>
                </li>
                <span>-</span>
                <li>
                    <Link className="nav-link" to="/fine_art">Fine Art</Link>
                </li>
                <span>-</span>
                <li>
                    <Link className="nav-link" to="/hire_me">Hire Me</Link>
                </li>
                <span>-</span>
                {/* <li>
                    <Link className="nav-link" to="/form">form</Link>
                </li>
                <span>-</span> */}
                <li>
                    <Link className="nav-link" to="/contact">Contact</Link>
                </li>
            </ul>
            <ul>
                <li id='cart'>
                    <Link id="basket" className="nav-link" to="/checkout"><BiBasket size={21} />{basket?.length}</Link>
                </li>
            </ul>    
        </div>
        <div id="header-div-fixed"></div>
        </div>
        </div>
    )
}

export default Header
