import React, { useState, useEffect } from 'react';
import './Checkout.css';
import Subtotal from '../Subtotal';
import { useStateValue } from '../StateProvider';
import CheckoutProduct from '../CheckoutProduct';


function Checkout() {

    // eslint-disable-next-line
    const [{ basket }, dispatch] = useStateValue();
    // const localData = localStorage.getItem('cart');
    // const cartItems = JSON.parse(localData);

    const [emptyCart, setEmptyCart] = useState('');

    useEffect(() => {
        if (basket.length === 0) {
            setEmptyCart('Oops! It looks like you do not have anything in your cart...');
        }
    }, [basket])

    return (
        <div className="checkout">
            <div id="checkout-left">
                <div id='checkout-image'>
                    <h2 id="checkout-title">
                        Your Shopping Cart
                    </h2>
                    {basket.map(item => (
                            <CheckoutProduct
                            key={item.id}
                            image={item.image}
                            title={item.title}
                            size={item.size}
                            material={item.material}
                            price={item.price}
                            id={item.id} 
                            />
                        ))}
                    <p id='empty-cart-title' >{emptyCart}</p>    
                </div>
            </div>
            <div id="checkout-right">
                <Subtotal />
            </div>
        </div>
    )
}

export default Checkout
