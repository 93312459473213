import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import './FineArt.css';
import { db } from '../firebase'
import Photo from '../Photo';


// material1_Style: {display: 'none'}

function FineArt() {

    const { url } = useRouteMatch();

    // connection check
    // eslint-disable-next-line
    const [connecting, setConnecting] = useState(true);
    // eslint-disable-next-line
    const [cannotConnect, setCannotConnect] = useState(false);

    // all needed data from database
    const [allRatios, setAllRatios] = useState([])
    const [fineArtPhotos, setFineArtPhotos] = useState([])

    // const [borderColor, setBorderColor] = useState('')


    // get ratios
    useEffect(() => {

        const subscriber = db
            .collection('aspect_ratios')
            .doc('ratios')
            .onSnapshot(snapshot => {
                setAllRatios(snapshot.data());
            })

        // cleanup function
        return () => subscriber()
        
    }, []) // empty array means this only runs once

    // get Fine Art Photos
    useEffect(() => {

        const fineArtSubscriber = db
            .collection('photos')
            .doc('photos')
            .collection('Fine Art')
            .onSnapshot(snapshot => {
                setFineArtPhotos(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
                // console.log(fineArtPhotos.length)
                if (allRatios !== undefined) {
                    setConnecting(false)
                } else {
                    setTimeout(() => {
                        setConnecting(false);
                        setCannotConnect(true);
                    }, 2000)
                }
            })

        // cleanup function
        return () => fineArtSubscriber()
        
    }, [fineArtPhotos, allRatios]) // empty array means this only runs once
    

    return (
        <div id="home">
            <div id="cannot_connect" style={{'display': cannotConnect ? 'block' : 'none'}} >
                <div>
                    <h1>We are sorry, but there seems to be an error with your connection...</h1>
                    <p style={{'width': 'max-content', 'margin': 'auto'}}>Make sure you are connected to the internet and refresh the page.</p>
                </div>
            </div>
            <div id="connecting_message" style={{'display': connecting ? 'block' : 'none'}}>
                <h1>Connecting to database...</h1>
            </div>
        <div id="featured" style={{'display': connecting && cannotConnect ? 'none' : 'block'}}>
        <div id="featured-page">
        <p id="featured-title">Fine Art</p>
        </div>
        </div>
            <div id="photos">


            {fineArtPhotos.map((item, i) => {

                let ordered_sizes = []

                function compareNumbers(a, b) {
                    return a - b;
                }

                let all_sizes = Object.keys(allRatios[item.data.aspectRatio]).sort()
                let sizes = []

                for (let ratio = 0; ratio < all_sizes.length; ratio++) {
                    if (all_sizes[ratio] === 'Digital') {
                        break
                    } else {
                        sizes.push(all_sizes[ratio])
                    }
                }

                let first_nums = []

                for (let size = 0; size < sizes.length; size++) {
                    let split_size = sizes[size].split('X')
                    first_nums.push(split_size[0])
                }

                first_nums.sort(compareNumbers)

                for (let num = 0; num < first_nums.length; num++) {
                    for (let size = 0; size < sizes.length; size++) {
                        if (sizes[size].startsWith(first_nums[num])) {
                            ordered_sizes.push(sizes[size])
                            break
                        }
                    }
                }

                const calculatePrice = (index, material) => {
                    const imageAspect = item.data.aspectRatio
                    const basePrice = allRatios[imageAspect][ordered_sizes[index]][material]
                    return Math.round((basePrice + item.data.mark_up/100 * basePrice) * 100) / 100
                }

                return (
                <Link
                key={item.id}
                to={{pathname: `${url}/${item.data.photo_name}`,
                state: {
                    id: item.id,
                    title: item.data.photo_name,
                    description: item.data.description,
                    image: item.data.download_url,
                    size1: ordered_sizes[0],
                    size2: ordered_sizes[1],
                    size3: ordered_sizes[2],
                    material1: item.data.material_one,
                    material2: item.data.material_two,
                    material3: item.data.material_three,
                    digital: 'Digital',
                    // price
                    price_Digital: allRatios[item.data.aspectRatio]['Digital'] + item.data.mark_up/100 * allRatios[item.data.aspectRatio]['Digital'],
                    price_M1_S1: calculatePrice(0, item.data.material_one),
                    price_M1_S2: calculatePrice(1, item.data.material_one),
                    price_M1_S3: calculatePrice(2, item.data.material_one),
                    price_M2_S1: calculatePrice(0, item.data.material_two),
                    price_M2_S2: calculatePrice(1, item.data.material_two),
                    price_M2_S3: calculatePrice(2, item.data.material_two),
                    price_M3_S1: calculatePrice(0, item.data.material_three),
                    price_M3_S2: calculatePrice(1, item.data.material_three),
                    price_M3_S3: calculatePrice(2, item.data.material_three),
                }}}>
                    <Photo id="photo_link" image={item.data.download_url} borderColor="#282C34" delayTime={String(0.2*i+0.2)+'s'} /> 
                    {/* color and delay will also need to change */}
                </Link>
                )
            })}
            </div>
        </div>       
    )
}

export default FineArt
