import React, { useState, useEffect } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import '../photos/UploadNewPhoto.css'
import { db } from '../../firebase'

function EditItem() {

    const [error, setError] = useState('')
    const { currentUser, logout } = useAuth()
    const history = useHistory()
    
    // loaded from database state
    const [items, setItems] = useState([])
    const [itemName, setItemName] = useState('')

    const [description, setDescription] = useState('')
    const [images, setImages] = useState([])
    const [previewImages, setPreviewImages] = useState([])
    const [price, setPrice] = useState(0)

    const [uploading, setUploading] = useState(false);
    const [connecting, setConnecting] = useState(true);
    const [cannotConnect, setCannotConnect] = useState(false);
    const [areYouSureBox, setAreYouSureBox] = useState(false)

    async function handleLogout() {
        setError("")

        try {
            await logout()
            history.push("/admin/login")
        } catch {
            setError("Failed to logout")
        }
    }

    // get Items
    useEffect(() => {

        const itemSubscriber = db
            .collection('items')
            .onSnapshot(snapshot => {
                setItems(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
                if (items !== undefined) {
                    setConnecting(false)
                } else {
                    setTimeout(() => {
                        setConnecting(false);
                        setCannotConnect(true);
                    }, 2000)
                }
            })

        // cleanup function
        return () => itemSubscriber()
        
    }, [items])

    const handleSubmit = (e) => {
        e.preventDefault();

        // add to database
        setUploading(true);

        db
        .collection('items')
        .doc(itemName)
        .set({
            description: description,
            images: images,
            preview_images: previewImages,
            price: price
        }).then(() => {
            // reset form w/ refresh
            window.location.reload()
        })

    }

    const handleDelete = (e) => {
        e.preventDefault();

        setAreYouSureBox(true)
    }

    const areYouSureCancel = (e) => {
        e.preventDefault();

        setAreYouSureBox(false)
    }
    
    const areYouSureConfirm = (e) => {
        e.preventDefault();

        db
            .collection('items')
            .doc(itemName)
            .delete()

        setAreYouSureBox(true)
    }

    const changeValsForItem = (e) => {

        setItemName(e.target.value)

        const all_options = document.getElementsByTagName('option')
        for (let option of all_options) {
            option.removeAttribute('selected')
        }

        for (let selected_item in items) {
            const photo_preview = document.getElementById("photo-preview")
            if (e.target.value === items[selected_item].id) {
                photo_preview.setAttribute('src', items[selected_item].data.images[0])
                // above only previews the first image
                setDescription(items[selected_item].data.description)
                setImages(items[selected_item].data.images)
                setPreviewImages(items[selected_item].data.preview_images)
                setPrice(items[selected_item].data.price)
            }
        }
    }

    return (
        <div className="upload-new-div">
            <div id="are_you_sure_box" style={{'display': areYouSureBox ? 'block' : 'none'}}>
                Are you sure you would like to delete this item? <br />
                This action cannot be undone. <br />
                <div id="are_you_sure_box_options">
                    <button onClick={areYouSureConfirm}>yes, I'm sure</button>
                    <button onClick={areYouSureCancel}>cancel</button>
                </div>
            </div>
            <div id="uploading_message" style={{'display': uploading ? 'block' : 'none'}}>
                <h1>Uploading...</h1>
            </div>
            <div id="cannot_connect" style={{'display': cannotConnect ? 'block' : 'none'}} >
                <div>
                    <h1>We are sorry, but there seems to be an error with your connection...</h1>
                    <p>Make sure you are connected to the internet and refresh the page.</p>
                </div>
            </div>
            <div id="connecting_message" style={{'display': connecting ? 'block' : 'none'}}>
                <h1>Connecting...</h1>
            </div>
            <div id="logged_email">
                <div id="email_admin"><strong>Email: </strong>{currentUser.email}</div>
                <button onClick={handleLogout}>Log out</button>
                {error}
            </div>
            <h1>Edit Existing Item info</h1>
            <p>back to <a href="/admin/update_site_info">dashboard</a></p>
            <form id="submit-photo" onSubmit={handleSubmit}>
                <label>Item Name:</label>
                <br />
                <select name="photo_name" defaultValue="choose item" onChange={changeValsForItem} >
                    <option disabled>choose item</option>
                    {/* eslint-disable-next-line */}
                    {items.map(item => {
                        if (item.id !== 'selected_featured_item') {
                            return (<option id={item} key={item} value={item.id}>{item.id}</option>)
                        }
                    })}
                </select>
                <button disabled={itemName !== '' ? false : true} onClick={handleDelete}>delete item</button>
                <br />
                <label>Item Description:</label>
                <br />
                <textarea type="text" value={description} onChange={e => setDescription(e.target.value)} />
                <br />
                <label>price:</label>
                <input id="percent_markup" type="number" value={price} onChange={e => setPrice(e.target.value)} />
                <br />
                <input type="submit" value="submit changes" />
            </form>
            <img alt="preview of uploaded file" id='photo-preview' src="" />
        </div>
    )
}

export default EditItem
