import { React, useState, useRef } from 'react';
import './Contact.css';
import { GrSend } from 'react-icons/gr';
import { db } from '../firebase';
import firebase from 'firebase/app';
import Recaptcha from 'react-google-recaptcha';

function Contact() {

    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [email, setEmail] = useState('');
    const [comments, setComments] = useState('');

    const [formSubmit, setFormSubmit] = useState('');
    const [isVerified, setIsVerified] = useState(false);

    const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

    const reRef = useRef()

    // create not a robot form for submitting inqueries...

    //styleOnEnter

    window.addEventListener('input', function() {
        var fNameStyle = document.querySelector("#first-name-input");
        var lNameStyle = document.querySelector("#last-name-input");
        var emailStyle = document.querySelector("#email-input");
        var commentStyle = document.querySelector("#comment-input");

        
        if (fNameStyle == null) {
            return;
        }
        if (lNameStyle == null) {
            return;
        }
        if (emailStyle == null) {
            return;
        }
        if (commentStyle == null) {
            return;
        }


        if (fName.length > 0) {
            fNameStyle.classList.add("styleOnEnter");
            fNameStyle.classList.remove("styleBeforeEnter");
            fNameStyle.classList.remove('styleOnError');
        } else if (fName.length === 0) {
            fNameStyle.classList.add("styleBeforeEnter");
            fNameStyle.classList.remove("styleOnEnter");
        }
        if (lName.length > 0) {
            lNameStyle.classList.add("styleOnEnter");
            lNameStyle.classList.remove("styleBeforeEnter");
            lNameStyle.classList.remove('styleOnError');
        } else if (lName.length === 0) {
            lNameStyle.classList.add("styleBeforeEnter");
            lNameStyle.classList.remove("styleOnEnter");
        }
        if (email.length > 0) {
            emailStyle.classList.add("styleOnEnter");
            emailStyle.classList.remove("styleBeforeEnter");
            emailStyle.classList.remove('styleOnError');
        } else if (email.length === 0) {
            emailStyle.classList.add("styleBeforeEnter");
            emailStyle.classList.remove("styleOnEnter");
        }
        if (comments.length > 0) {
            commentStyle.classList.add("styleOnEnter");
            commentStyle.classList.remove("styleBeforeEnter");
            commentStyle.classList.remove('styleOnError');
        } else if (comments.length === 0) {
            commentStyle.classList.add("styleBeforeEnter");
            commentStyle.classList.remove("styleOnEnter");
        }
    });

    const submitValidation = (event) => {
        event.preventDefault();
        // try app check for firebase
        // apparently appCheck is not a function...
        // const appCheck = firebase.appCheck();
        // appCheck.activate(recaptchaKey)

        // auth for guest via firebase
        firebase.auth().signInAnonymously().then(() => {
            console.log('signed in anonymously')
        }).catch((error) => {
            const errorCode = error.errorCode
            const errorMessage = error.message
            console.log(errorCode, errorMessage);
        }).then(() => {
            var fNameStyle = document.querySelector("#first-name-input");
            var lNameStyle = document.querySelector("#last-name-input");
            var emailStyle = document.querySelector("#email-input");
            var commentStyle = document.querySelector("#comment-input");
            var requiredFName = document.querySelector("#required-fname");
            var requiredLName = document.querySelector("#required-lname");
            var requiredEmail = document.querySelector("#required-email");
            var requiredComment = document.querySelector("#required-comments");
    
            
            if (comments.length === 0) {
                commentStyle.classList.add('styleOnError');
                requiredComment.classList.remove('visibility-hidden');
                requiredComment.classList.add('visibility-visibile');
                event.preventDefault();
            } else if (comments.length > 0) {
                requiredComment.classList.remove('visibility-visible');
                requiredComment.classList.add('visibility-hidden');
                event.preventDefault();
            }
            if (email.length === 0) {
                emailStyle.classList.add('styleOnError');
                requiredEmail.classList.remove('visibility-hidden');
                requiredEmail.classList.add('visibility-visible');
                event.preventDefault();
            } else if (email.length > 0) {
                requiredEmail.classList.remove('visibility-visible');
                requiredEmail.classList.add('visibility-hidden');
                event.preventDefault();
            }
            if (email.indexOf('@') < 0) {
                emailStyle.classList.add('styleOnError');
                requiredEmail.classList.remove('visibility-hidden');
                requiredEmail.classList.add('visibility-visible');
                event.preventDefault();
            } else if (email.length > 0) {
                requiredEmail.classList.remove('visibility-visible');
                requiredEmail.classList.add('visibility-hidden');
                event.preventDefault();
            }
            if (lName.length === 0) {
                lNameStyle.classList.add('styleOnError');
                requiredLName.classList.remove('visibility-hidden');
                requiredLName.classList.add('visibility-visible');
                event.preventDefault();
            } else if (lName.length > 0) {
                requiredLName.classList.remove('visibility-visible');
                requiredLName.classList.add('visibility-hidden');
                event.preventDefault();
            }
            if (fName.length === 0) {
                fNameStyle.classList.add('styleOnError');
                requiredFName.classList.remove('visibility-hidden');
                requiredFName.classList.add('visibility-visible');
                event.preventDefault();
            } else if (fName.length > 0) {
                requiredFName.classList.remove('visibility-visible');
                requiredFName.classList.add('visibility-hidden');
                event.preventDefault();
            }
            if (fName.length > 0 &&
                lName.length > 0 &&
                email.length > 0 &&
                comments.length > 0   
            ) {
                    db
                        .collection('inquiries')
                        .doc(Date.now().toString())
                        .set({
                            first_name: fName,
                            last_name: lName,
                            email: email,
                            inquiry: comments,
                            created: Date.now(),
                        }).then(() => {
                            setFormSubmit('Message Sent!');
                            // sign out here
                            firebase.auth().signOut().then(() => {
                                console.log('signed out')
                            }).catch((error) => {
                                const errorCode = error.errorCode
                                const errorMessage = error.message
                                console.log(errorCode, errorMessage);
                            })
                            reRef.current.reset()
                        }).catch((error) => {
                            setFormSubmit(error.message);
                            firebase.auth().signOut().then(() => {
                                console.log('signed out')
                            }).catch((error) => {
                                const errorCode = error.errorCode
                                const errorMessage = error.message
                                console.log(errorCode, errorMessage);
                            })
                            reRef.current.reset()
                        });

                    // send email to customer
                    db
                    .collection('emails')
                    .doc('inquiry_customer' + Date.now().toString())
                    .set({
                        to: email,
                        from: 'support@alechoveyphotography.com',
                        message: {
                            subject: 'Inquiry Received!',
                            html: 'Thank you for reaching out to me! I should respond to your message within 1 business day.'
                        },
                    })

                    // send email to myself
                    db
                    .collection('emails')
                    .doc('inquiry_owner' + Date.now().toString())
                    .set({
                        to: 'alechoveyphotography@gmail.com',
                        from: email,
                        replyTo: email,
                        message: {
                            subject: 'Inquiry',
                            html: `<p>Message from: <strong>${fName} ${lName}</strong></p><p>${comments}</p>`
                        },
                    })
    
                    setFName('');
                    setLName('');
                    setEmail('');
                    setComments('');
                }
            })
        }

    const handleVerification = (response) => {
        setIsVerified(true)
    }

    return (
        <div id="contact-full-page">
        <div id="contact">
            <h1 id="contact-me-title">Contact Me</h1>
            <p>Thank you for taking the time to browse through my extensive gallery. Each photo has been specifically chosen to encapsulate my best work.</p>
            <p>For any inquiries on prints or photoshoots, please fill out the form below.</p>
            <p>Please feel free to also give me suggestions on how I can make the user experience of the website better for you. I am the sole developer and am always open to suggestions.</p>
            <h2>Thank You!</h2>
            <div id="contact-form-div">
                <form id="contact-form">
                    <div id="names">
                        <div id="first-name">
                            <label>First Name: </label><br />
                            <input className="styleBeforeEnter" id="first-name-input" onChange={e => setFName(e.target.value)} value={fName} type="text" /><br />
                            <div id='required'>
                                <small className="visibility-hidden" id="required-fname" >*Enter your first name</small><br />
                            </div>
                        </div>
                        <div id="last-name">
                            <label>Last Name: </label><br />
                            <input className="styleBeforeEnter" id="last-name-input" onChange={e => setLName(e.target.value)} value={lName} type="text" /><br />
                            <div id='required'>
                                <small className="visibility-hidden" id="required-lname" >*Enter your last name</small><br />
                            </div>
                        </div>
                    </div>
                    <div id="email">
                        <label>Your Email: </label><br />
                        <input className="styleBeforeEnter" id="email-input" onChange={e => setEmail(e.target.value)} value={email} type="text" />
                        <div id='required'>
                            <small className="visibility-hidden" id="required-email" >*Enter a valid email address</small><br />
                        </div>
                    </div>
                    <br />
                    <div id="comments">
                        <label>Questions/Concerns: </label><br />
                        <textarea className="styleBeforeEnter" id="comment-input" onChange={e => setComments(e.target.value)} value={comments} type="text-box" />
                        <div id='required'>
                            <small className="visibility-hidden" id="required-comments" >*Please enter your message</small>
                        </div>
                    </div>
                    <Recaptcha
                    sitekey={recaptchaKey}
                    onChange={handleVerification}
                    className="recaptcha-contact"
                    ref={reRef}
                    />
                    <div id="submit">
                        <button disabled={isVerified ? false : true} onClick={submitValidation} type="submit" htmlFor="contact-form" >Send {<GrSend size={10} />}</button>
                    </div>
                </form>
            </div>
            <div className='submit-popup'>
                {formSubmit}
            </div>
        </div>
        </div>
    )
}

export default Contact
