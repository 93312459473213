import React from 'react';
import './CheckoutProduct.css';
import { useStateValue } from './StateProvider';
import CurrencyFormat from 'react-currency-format';

function CheckoutProduct({ id, image, title, size, price, material, buttonStyle, fullStyle }) {

    // eslint-disable-next-line
    const [{basket}, dispatch] = useStateValue();

    const removeFromCart = () => {
        dispatch({
            type: 'REMOVE_FROM_CART',
            id: id,
        })
    }

    return (
        <div className='all-products'>
            <div className='checkoutProduct' style={fullStyle} >
                <img className='checkoutProduct-image' src={image} alt='checkout' />

                <div className='checkoutProduct-info'>
                    <p className='checkoutProduct-title'>{title}</p>
                    <p className='checkoutProduct-size'>{size}</p>
                    <p className='checkoutProduct-size'>{material}</p>
                    <p className='checkoutProduct-price'>
                    {price === null ? <p>$</p> : <CurrencyFormat
                    renderText={(value) => (
                        <span>{value}</span>
                    )}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={parseFloat(price)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    />}
                    </p>
                    <button style={buttonStyle} onClick={removeFromCart}>remove from cart</button>

                </div>
            </div>
        </div>
    )
}

export default CheckoutProduct
