import { React, useState, useEffect } from 'react';
import '../Preview.css';
import { useStateValue } from '../StateProvider';
import { db } from '../firebase';
import CurrencyFormat from 'react-currency-format';


function EarlyBird() {

    const [size, setSize] = useState('');
    const [material, setMaterial] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [price, setPrice] = useState(null);
    const [wasPrice, setWasPrice] = useState(null);

    const [addToCartMessage, setAddToCartMessage] = useState('');
    const [clickNumber, setClickNumber] = useState(1);

    const [materialDescription, setMaterialDescription] = useState('');

    // eslint-disable-next-line
    const [{ basket }, dispatch] = useStateValue();

    // connection check
    // eslint-disable-next-line
    const [connecting, setConnecting] = useState(true);
    // eslint-disable-next-line
    const [cannotConnect, setCannotConnect] = useState(false);

    // all needed data from database
    const [allRatios, setAllRatios] = useState([])
    const [earlyBirdPhotos, setEarlyBirdPhotos] = useState([])

    // all state for page display
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [id, setId] = useState('');
    const [aspectRatio, setAspectRatio] = useState('');
    const [markUp, setMarkUp] = useState('');
    const [allSizes, setAllSizes] = useState([]);
    const [sizeOne, setSizeOne] = useState('');
    const [sizeTwo, setSizeTwo] = useState('');
    const [sizeThree, setSizeThree] = useState('');
    const [materialOne, setMaterialOne] = useState('');
    const [materialTwo, setMaterialTwo] = useState('');
    const [materialThree, setMaterialThree] = useState('');
    const digital = 'Digital';
    const [priceM1S1, setPriceM1S1] = useState('');
    const [priceM1S2, setPriceM1S2] = useState('');
    const [priceM1S3, setPriceM1S3] = useState('');
    const [priceM2S1, setPriceM2S1] = useState('');
    const [priceM2S2, setPriceM2S2] = useState('');
    const [priceM2S3, setPriceM2S3] = useState('');
    const [priceM3S1, setPriceM3S1] = useState('');
    const [priceM3S2, setPriceM3S2] = useState('');
    const [priceM3S3, setPriceM3S3] = useState('');

    // const [borderColor, setBorderColor] = useState('')


    // const formatCurrency = (number) => {
    //     let new_num = String(number)
    //     let split_num = String(number).split('.')
    //     if (split_num.length !== 2) {
    //         new_num = String(number) + '.00'
    //     } else if (split_num[1].length === 1) {
    //         new_num = String(number) + '0'
    //     } else if (split_num[1].length > 2) {
    //         if (split_num[1][0] === '0') {
    //             let second_num = String(Math.round((split_num[1]).slice(1,3) / 10))
    //             // console.log(Math.round((split_num[1]).slice(1,3) / 10))
    //             new_num = split_num[0] + '.0' + String(second_num[0])
    //             return new_num
    //         }
    //         // let second_num = String(Math.round((split_num[1]) / 10))[0] + String(Math.round((split_num[1]) / 10))[1]
    //         let second_num = Math.round(parseInt(String(split_num[1]).slice(0,3)) / 10)
    //         // console.log(second_num)
    //         new_num = split_num[0] + '.' + String(second_num)
    //     }
    //     return new_num
    // }

    // get ratios
    useEffect(() => {

        const subscriber = db
            .collection('aspect_ratios')
            .doc('ratios')
            .onSnapshot(snapshot => {
                setAllRatios(snapshot.data());
            })

        // cleanup function
        return () => subscriber()
        
    }, []) // empty array means this only runs once

    // get Early Bird Photos
    useEffect(() => {

        const fineArtSubscriber = db
            .collection('photos')
            .doc('photos')
            .collection('Early Bird')
            .onSnapshot(snapshot => {
                setEarlyBirdPhotos(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
                // set all state here... so it will default to nothing at start
                if (earlyBirdPhotos[0] !== undefined && allRatios !== undefined) {

                    let ordered_sizes = []

                    function compareNumbers(a, b) {
                        return a - b;
                    }
                
                    let sizes = []
                
                    for (let ratio = 0; ratio < allSizes.length; ratio++) {
                        if (allSizes[ratio] === 'Digital') {
                            break
                        } else {
                            sizes.push(allSizes[ratio])
                        }
                    }
                
                    let first_nums = []
                
                    for (let size = 0; size < sizes.length; size++) {
                        let split_size = sizes[size].split('X')
                        first_nums.push(split_size[0])
                    }
                
                    first_nums.sort(compareNumbers)
                
                    for (let num = 0; num < first_nums.length; num++) {
                        for (let size = 0; size < sizes.length; size++) {
                            if (sizes[size].startsWith(first_nums[num])) {
                                ordered_sizes.push(sizes[size])
                                break
                            }
                        }
                    }

                    const calculatePrice = (index, material) => {
                        if (allRatios[aspectRatio] !== undefined) {
                            const base = allRatios[aspectRatio][ordered_sizes[index]][material];
                            return base + markUp/100 * base;
                        }
                    }

                    setSizeOne(ordered_sizes[0])
                    setSizeTwo(ordered_sizes[1])
                    setSizeThree(ordered_sizes[2])
                
                    setName(earlyBirdPhotos[0].data.photo_name)
                    setDescription(earlyBirdPhotos[0].data.description)
                    setImage(earlyBirdPhotos[0].data.download_url)
                    setId(earlyBirdPhotos[0].id)
                    setAspectRatio(earlyBirdPhotos[0].data.aspectRatio)
                    setMarkUp(earlyBirdPhotos[0].data.mark_up)
                    setAllSizes(Object.keys(allRatios[earlyBirdPhotos[0].data.aspectRatio]).sort())
                    setMaterialOne(earlyBirdPhotos[0].data.material_one)
                    setMaterialTwo(earlyBirdPhotos[0].data.material_two)
                    setMaterialThree(earlyBirdPhotos[0].data.material_three)

                    // set prices
                    setPriceM1S1(calculatePrice(0, earlyBirdPhotos[0].data.material_one))
                    setPriceM1S2(calculatePrice(1, earlyBirdPhotos[0].data.material_one))
                    setPriceM1S3(calculatePrice(2, earlyBirdPhotos[0].data.material_one))
                    setPriceM2S1(calculatePrice(0, earlyBirdPhotos[0].data.material_two))
                    setPriceM2S2(calculatePrice(1, earlyBirdPhotos[0].data.material_two))
                    setPriceM2S3(calculatePrice(2, earlyBirdPhotos[0].data.material_two))
                    setPriceM3S1(calculatePrice(0, earlyBirdPhotos[0].data.material_three))
                    setPriceM3S2(calculatePrice(1, earlyBirdPhotos[0].data.material_three))
                    setPriceM3S3(calculatePrice(2, earlyBirdPhotos[0].data.material_three))

                    setConnecting(false)
                } else {
                    setTimeout(() => {
                        setConnecting(false);
                        setCannotConnect(true);
                    }, 2000)
                }
            })

        // cleanup function
        return () => fineArtSubscriber()
        
    }, [earlyBirdPhotos, allRatios, aspectRatio, allSizes, markUp, materialOne, materialTwo, materialThree])


    // eslint-disable-next-line
    const calculateDiscountedPrice = (original_price) => {
        return original_price - (original_price * 0.35)
    }


    // Sizes
    let size1 = sizeOne;
    let size2 = sizeTwo;
    let size3 = sizeThree;

    // Pricing (w/o discount)
    let price_Digital_full = (earlyBirdPhotos[0] ? allRatios[earlyBirdPhotos[0].data.aspectRatio]['Digital'] + earlyBirdPhotos[0].data.mark_up/100 * allRatios[earlyBirdPhotos[0].data.aspectRatio]['Digital'] : 0);
    // Material 1
    let price_M1_S1_full = priceM1S1;
    let price_M1_S2_full = priceM1S2;
    let price_M1_S3_full = priceM1S3;
    // Material 2
    let price_M2_S1_full = priceM2S1;
    let price_M2_S2_full = priceM2S2;
    let price_M2_S3_full = priceM2S3;
    // Material 3
    let price_M3_S1_full = priceM3S1;
    let price_M3_S2_full = priceM3S2;
    let price_M3_S3_full = priceM3S3;

    // Pricing (w/ discount)
    let price_Digital = calculateDiscountedPrice(price_Digital_full);
    // Material 1
    let price_M1_S1 = calculateDiscountedPrice(price_M1_S1_full);
    let price_M1_S2 = calculateDiscountedPrice(price_M1_S2_full);
    let price_M1_S3 = calculateDiscountedPrice(price_M1_S3_full);
    // Material 2
    let price_M2_S1 = calculateDiscountedPrice(price_M2_S1_full);
    let price_M2_S2 = calculateDiscountedPrice(price_M2_S2_full);
    let price_M2_S3 = calculateDiscountedPrice(price_M2_S3_full);
    // Material 3
    let price_M3_S1 = calculateDiscountedPrice(price_M3_S1_full);
    let price_M3_S2 = calculateDiscountedPrice(price_M3_S2_full);
    let price_M3_S3 = calculateDiscountedPrice(price_M3_S3_full);

    // Dropdown Styling
    // let size1_Style = null;
    // let size2_Style = null;
    // let size3_Style = null;
    // let materialOne_Style = null;
    // let materialTwo_Style = null;
    // let materialThree_Style = null;
    // let digital_Style = null;


    let style = {
        backgroundColor: "#F1F3F4"
    }

    const priceSizeChange = (e) => {

        var button = document.querySelector('#basket-button');
        let buttonChangeOnSize = e.target.value;

        setSize(e.target.value);

        if (buttonChangeOnSize !== "") {
            if (button.classList === 'basket-button') {
                return;
            } else {
            button.classList.remove('error');
            button.classList.add('basket-button');
            }
        }
    }

    const priceMaterialChange = (e) => {

        var button = document.querySelector('#basket-button');
        let buttonChangeOnSize = e.target.value;

        setMaterial(e.target.value);

        if (e.target.value === 'Digital') {
            const size1Button = document.getElementById(size1)
            const size2Button = document.getElementById(size2)
            const size3Button = document.getElementById(size3)

            size1Button.checked = false
            size2Button.checked = false
            size3Button.checked = false
        }

        if (buttonChangeOnSize !== "") {
            if (button.classList === 'basket-button') {
                return;
            } else {
            button.classList.remove('error');
            button.classList.add('basket-button');
            }
        }

    }

    useEffect(() => {

        if (material === materialOne && size === size1) {
            setPrice(price_M1_S1);
            setWasPrice(price_M1_S1_full);
        }
        if (material === materialOne && size === size2) {
            setPrice(price_M1_S2);
            setWasPrice(price_M1_S2_full);
        }
        if (material === materialOne && size === size3) {
            setPrice(price_M1_S3);
            setWasPrice(price_M1_S3_full);
        }
        if (material === materialTwo && size === size1) {
            setPrice(price_M2_S1);
            setWasPrice(price_M2_S1_full);
        }
        if (material === materialTwo && size === size2) {
            setPrice(price_M2_S2);
            setWasPrice(price_M2_S2_full);
        }
        if (material === materialTwo && size === size3) {
            setPrice(price_M2_S3);
            setWasPrice(price_M2_S3_full);
        }
        if (material === materialThree && size === size1) {
            setPrice(price_M3_S1);
            setWasPrice(price_M3_S1_full);
        }
        if (material === materialThree && size === size2) {
            setPrice(price_M3_S2);
            setWasPrice(price_M3_S2_full);
        }
        if (material === materialThree && size === size3) {
            setPrice(price_M3_S3);
            setWasPrice(price_M3_S3_full);
        }
        if (material === digital) {
            setPrice(price_Digital);
            setWasPrice(price_Digital_full);
            setSize("Full Resolution");
            setIsDisabled(true);
        } else if (material !== digital) {
            setIsDisabled(false);
        }

        if (material === 'Canvas') {
            setMaterialDescription('Canvas prints are printed on high quality canvas paper and wrapped around a 2" frame. The best quality canvas prints are usually bigger because they have a tendancy to distort finer details. Canvas prints do not come with a frame.')
        } else if (material === 'Metal') {
            setMaterialDescription('Metal prints are printed on glossy aluminum and come with a small nail mount in the back. The mount gives the appearance that print is suspended from the wall. Metal prints tend to be best at highligting details and making prints appear vibrant. Metal prints are great if you are looking for a modern look.')
        } else if (material === 'Paper') {
            setMaterialDescription('Paper prints are the cheapest prints but are the most versetile. Printed on high quality glossy photo paper, paper prints can be mounted and framed in whatever way you choose. Paper prints are shipped with extra care in order to avoid bending or creasing.')
        } else if (material === 'Digital') {
            setMaterialDescription('Digital prints are the most versatile. Use them as a desktop background or have them printed on your favorite material as many times as you like. After ordering a digital print, a full resolution file will be sent directly to your email. Please contact myself if you plan to use these digital files for commercial use as there will be separate pricing for licensing agreements.')
        } else {
            setMaterialDescription('');
        }
        
    }, [
        material, 
        size,
        materialOne,
        materialTwo,
        materialThree,
        size1,
        size2,
        size3,
        digital,
        price_M1_S1,
        price_M1_S2,
        price_M1_S3,
        price_M2_S1,
        price_M2_S2,
        price_M2_S3,
        price_M3_S1,
        price_M3_S2,
        price_M3_S3,
        price_Digital,
        price_Digital_full,
        price_M1_S1_full,
        price_M1_S2_full,
        price_M1_S3_full,
        price_M2_S1_full,
        price_M2_S2_full,
        price_M2_S3_full,
        price_M3_S1_full,
        price_M3_S2_full,
        price_M3_S3_full,
        ]
    )

    const addToBasket = () => {

        var button = document.querySelector('#basket-button');
        // let message = document.querySelector('.add-to-cart-message');

        if (size === "" || material === "") {
            // alert('test');
            button.classList.add('error');
            button.classList.remove('basket-button');
        } else if (size === "Full Resolution" && material !== 'Digital') {
            button.classList.add('error');
            button.classList.remove('basket-button');
        } else if (size !== "" && material !== "") {
            dispatch({
                type: 'ADD_TO_BASKET',
                item: {
                    id: Date.now() + id,
                    title: name,
                    image: image,
                    material: material,
                    size: size,
                    price: price,
                }
            })
            setClickNumber(clickNumber + 1);
            if (clickNumber === 1) {
                setAddToCartMessage(`You have added ${clickNumber} ${name} photo to your cart!`);
            } else {
                setAddToCartMessage(`You have added ${clickNumber} ${name} photos to your cart!`);
            }
        }
    }


    return (
        <div className='preview'>
            {/* <Link to="/featured">back</Link> */}
            <h1 id="early">Early Bird Special</h1>
            <h3 id="save">Save 35% before it's too late!</h3>
            <div id="preview-full">
                <div id="preview-column-one" style={style} >
                    <h1 className="column-header" >{name}</h1>
                    <img id="preview-img"
                    src={image}
                    alt='*' 
                    />
                    <p id="description" ><i>{description}</i></p>
                </div>
                <div id="preview-column-two" style={style} >
                    <h1 className="column-header">Purchase Options</h1>
                    {price === null ? <p id='price'>$</p> : <CurrencyFormat
                    renderText={(value) => (
                        <span id="price">{value}</span>
                    )}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={parseFloat(price)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    />}
                    <p id="was">will be ${wasPrice}</p>
                    {/* <select defaultValue='select a material' onChange={priceMaterialChange} name='materials' id='materials' className="dropdown" >
                        <option disabled>select a material</option>
                        <option style={materialOne_Style} value={materialOne}>{materialOne}</option>
                        <option style={materialTwo_Style} value={materialTwo}>{materialTwo}</option>
                        <option style={materialThree_Style} value={materialThree}>{materialThree}</option>
                        <option style={digital_Style} value={digital}>{digital}</option>
                    </select> */}
                    <p className="choose-text">Choose your material:</p>
                    <div className="button-select-options">
                        <li>
                            <input type='radio' name='material' value={materialOne} id={materialOne} onChange={priceMaterialChange}/>
                            <label for={materialOne}>{materialOne}</label>
                        </li>
                        <li>
                            <input type='radio' name='material' value={materialTwo} id={materialTwo} onChange={priceMaterialChange}/>
                            <label for={materialTwo}>{materialTwo}</label>
                        </li>
                        <li>
                            <input type='radio' name='material' value={materialThree} id={materialThree} onChange={priceMaterialChange}/>
                            <label for={materialThree}>{materialThree}</label>
                        </li>
                        <li>
                            <input type='radio' name='material' value={digital} id={digital} onChange={priceMaterialChange}/>
                            <label for={digital}>{digital}</label>
                        </li>
                    </div>
                    {/* <select disabled={isDisabled} defaultValue='select a size' onChange={priceSizeChange} name='sizes' id='sizes' className="dropdown" >
                        <option disabled>select a size</option>
                        <option style={size1_Style} value={size1}>{size1}</option>
                        <option style={size2_Style} value={size2}>{size2}</option>
                        <option style={size3_Style} value={size3}>{size3}</option>
                    </select> */}
                        <p className="choose-text">Choose your size:</p>
                        <div id="button-select-options-two" className="button-select-options">
                            <li>
                                <input disabled={isDisabled} type='radio' name='size' value={size1} id={size1} onChange={priceSizeChange}/>
                                <label for={size1}>{size1}</label>
                            </li>
                            <li>
                                <input disabled={isDisabled} type='radio' name='size' value={size2} id={size2} onChange={priceSizeChange}/>
                                <label for={size2}>{size2}</label>
                            </li>
                            <li>
                                <input disabled={isDisabled} type='radio' name='size' value={size3} id={size3} onChange={priceSizeChange}/>
                                <label for={size3}>{size3}</label>
                            </li>
                            <li id="placeholder">
                                <label></label>
                            </li>
                        </div>
                        <br />
                        <div id="print-info">
                            <p>{materialDescription}</p>
                        </div>
                    <button id="basket-button" className="basket-button" onClick={addToBasket} >Add to Cart</button>
                </div>
            </div>
            <div className='add-to-cart-message'>
                {addToCartMessage}
            </div>
        </div>
    )
}

export default EarlyBird
