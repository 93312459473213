import React from 'react';
import './Landing.css';
// import { Link } from 'react-router-dom';

function Landing() {

    // POSSIBLE OPTIONS FOR PAGE

    // - cycle through testimonials
    // - video of photos
    return (
        <a id='landing-link' href='/featured'>
            <div style={{'backgroundImage': `url(${process.env.REACT_APP_LANDING_PAGE_IMAGE})`}} id='landing-page' className='landing-page'>
                <div className='space-around-text'>
                    <div id="nav-text">
                        <span id="name-text-home">
                            Alec Hovey
                        </span>
                            <br />
                        <span id="photo-text-home">
                            Photography
                        </span>
                    </div>
                    <div className='anywhere'>
                        <p>click anywhere to enter</p>
                    </div>
                    <div id="landing-page-info-box">
                        <div id="text">
                            <h2>Current Deal</h2>
                            <br />
                            {/* <p><i>Calendars</i> @ <strong>25%</strong> off!</p>
                            <p>Enter <strong>'HOLIDAY2021'</strong> at Checkout for <strong>15%</strong> off your entire order!</p> */}
                            <p><strong>FREE</strong> shipping on orders <i>over $100!</i></p>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    )
}

export default Landing
