import React, { useState, useEffect } from 'react'
import './UpdateSiteInfo.css'
import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import { db } from '../../firebase'
import AdminOrder from './AdminOrder';


function AllOrders() {
    const [error, setError] = useState('')
    const { currentUser, logout } = useAuth()
    const history = useHistory()

    const [orders, setOrders] = useState([]);

    async function handleLogout() {
        setError("")

        try {
            await logout()
            history.push("/admin/login")
        } catch {
            setError("Failed to logout")
        }
    }

    useEffect(() => {

        db
            .collection('orders')
            .orderBy('created', 'desc')
            .onSnapshot(snapshot => (
                setOrders(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            ))
    }, [])


    return (
        <div>
            <div id="logged_email">
                <div id="email_admin"><strong>Email: </strong>{currentUser.email}</div>
                <button onClick={handleLogout}>Log out</button>
                {error}
            </div>
            <h1>All Orders</h1>
            {/* <h2 id='returning-customer'>{returningCustomerText}</h2> */}
            <p>back to the <a href='/admin/update_site_info' >dashboard</a>.</p>
            <div className='orders-order'>
                {orders?.map(order => (
                    <AdminOrder order={order} />
                ))}
            </div>
        </div>
    )
}

export default AllOrders
