import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { useStateValue } from './StateProvider';
import Order from './Order';
import './Orders.css';

function Orders() {

    // eslint-disable-next-line
    const [{ email }, dispatch] = useStateValue();
    const [orders, setOrders] = useState([]);
    const [returningCustomerText, setReturningCustomerText] = useState('');

    // FOR DEVELOPMENT ---->>>>>

    // const [ordersByEmail, setOrdersByEmail] = useState('');

    // const submitEmail = (e) => {
    //     e.preventDefault();

    //     dispatch({
    //         type: 'SET_EMAIL',
    //         email: ordersByEmail
    //     })
    // }

    // END OF DEVELOPMENT CODE ------>>>>

    useEffect(() => {

        if (email) {

        db
            .collection('customers')
            .doc(email)
            .collection('orders')
            .orderBy('created', 'desc')
            .onSnapshot(snapshot => (
                setOrders(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            ))
        } else {
            setOrders([]);
            setReturningCustomerText('You must place an order or contact us in order to see your order history.');
        }

        // if (orders.length > 1) {
        //     setReturningCustomerText('It looks like you have shopped with us before. Have a discount on your next order!');
        // }

    }, [email])

    return (
        <div className='orders'>
            <h1>Your Orders...</h1>
            <h2 id='returning-customer'>{returningCustomerText}</h2>
            <p>back to the <a href='/fine_art' >FINE ART</a> page.</p>
            <div className='orders-order'>
                {orders?.map(order => (
                    <Order order={order} />
                ))}
            </div>
            {/* THIS FORM IS FOR DEV PURPOSES ONLY */}
            {/* <form onSubmit={submitEmail}>
            <input type='email' onChange={e => setOrdersByEmail(e.target.value)} />
            <button type="submit">Go!</button>
            </form> */}
            {/* THIS FORM IS FOR DEV PURPOSES ONLY */}
        </div>
    )
}

export default Orders
