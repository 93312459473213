import { React, useState, useEffect } from 'react';
import '../Preview.css';
import { useStateValue } from '../StateProvider';
import { db } from '../firebase';


// This page will be changed for our featured PRODUCT, we will need to move these photos over to the 'fine art' page and update the spreadsheet.

function Featured() {

    const [addToCartMessage, setAddToCartMessage] = useState('');
    const [clickNumber, setClickNumber] = useState(1);

    // eslint-disable-next-line
    const [{ basket }, dispatch] = useStateValue();

    const [items, setItems] = useState([]);

    const [selectedFeaturedItem, setSelectedFeaturedItem] = useState('')

    const [product, setProduct] = useState('');
    const [description, setDescription] = useState('');
    const [fullPrice, setFullPrice] = useState();
    // eslint-disable-next-line
    const [discountPrice, setDiscountPrice] = useState();
    const [photos, setPhotos] = useState([]);
    const [preview, setPreview] = useState([]);
    const [selectedProductPhoto, setSelectedProductPhoto] = useState('');

    /* Discount amount changes here! ---> */
    const discountPercentage = 0.25

    useEffect(() => {

        const subscriber = db
            .collection('items')
            .onSnapshot(snapshot => {
                setItems(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            })

            const calculatePrice = () => {
                const basePrice = items[0].data.price
                return Math.round((basePrice - discountPercentage * basePrice) * 100) / 100
            }

            // find the selected featured item
            for (var item_index in items) {
                if (items[item_index].id === 'selected_featured_item') {
                    setSelectedFeaturedItem(items[item_index].data.item)
                }
            }

            // set details for selected item
            for (var item in items) {
                if (items[item].id === selectedFeaturedItem) {
                    setProduct(items[item].id)
                    setPhotos(items[item].data.images)
                    setPreview(items[item].data.preview_images)
                    setDescription(items[item].data.description)
                    setFullPrice(items[item].data.price)
                    setDiscountPrice(calculatePrice())
                    if (!items[item].data.images.includes(selectedProductPhoto) && !items[item].data.preview_images.includes(selectedProductPhoto)) {
                        setSelectedProductPhoto(items[item].data.images[0])
                    }
                }
            }

        // cleanup function
        return () => subscriber()

    }, [items, fullPrice, selectedProductPhoto, selectedFeaturedItem])

    let style = {
        backgroundColor: "#F1F3F4"
    }

    const addToBasket = () => {

            dispatch({
                type: 'ADD_TO_BASKET',
                item: {
                    id: Date.now(),
                    title: product,
                    image: photos[0],
                    material: null,
                    size: null,
                    // this needs to change if using 'discountedPrice' >>>>
                    price: fullPrice,
                    shipping: null,
                }
            })
            setClickNumber(clickNumber + 1);
            if (clickNumber === 1) {
                setAddToCartMessage(`You have added ${clickNumber} ${product} to your cart!`);
            } else {
                setAddToCartMessage(`You have added ${clickNumber} ${product}'s to your cart!`);
            }
    }

    // const enlargeImage = () => {
    //     alert('clicking on this will enlarge the images')
    // }

    return (
        <div className="preview">
        <div id="featured-item-page">
        <div id="featured-page">
            <p id="featured-title">Featured Item:</p>
        </div>
        <div id="preview-full">
            {/* <h1 style={{'margin': 'auto'}} >coming soon...</h1> */}
                <div id="preview-column-one" style={style} >
                    <h1 className="column-header" >{product}</h1>
                    <img id="preview-img"
                    src={selectedProductPhoto}
                    alt='*'
                    style={{'marginBottom': '10px'}}
                    />
                    <div id="preview-product" style={{'marginBottom': '20px'}}>
                        {photos?.map((photo) => {
                            return (
                                <img alt="preview of product" className="preview-product" key={photo} src={photo} onClick={e => setSelectedProductPhoto(e.target.src)} />
                            )
                        })}
                    </div>
                    <p id="description" ><i>{description}</i></p>
                </div>
                <div id="preview-column-two" style={style} >
                    {/* Savings Tag (animated) ---> */}
                    {/* <div id="savings-second-shape-relative">
                        <div id="savings-second-shape-absolute">
                            
                        </div>
                    </div>
                    <div id="savings-main-shape-relative">
                        <div id="savings-main-shape-absolute">
                            
                            </div>
                        <div id="savings-main-shape-absolute">
                            <div id="savings-main-shape-text">
                                <p>25% OFF</p>
                                <p>-</p>
                                <p>ACT NOW</p>
                            </div>
                        </div>
                    </div> */}
                    <h1 className="column-header">Purchase Details</h1>
                    <p id="price">${fullPrice}</p>
                    {/* for discounted items ---> */}
                    {/* <p id="price">${discountPrice}</p>
                    <p id="was">originally ${fullPrice} - {discountPercentage * 100}% off!</p> */}
                    <p className="choose-text" style={{'marginBottom': '1rem'}}>Photos:</p>
                    <div id="preview-images">
                        {preview?.map((image) => {
                            return (
                            <img alt="preview of calendar" className="preview-image" onClick={e => setSelectedProductPhoto(e.target.src)} key={image} src={image} />
                            )
                        })}
                    </div>
                    <p style={{'margin': '1rem 0'}}>click to enlarge</p>
                    <button id="basket-button" className="basket-button" onClick={addToBasket} >Add to Cart</button>
                </div>
            </div>
            <div className='add-to-cart-message'>
                {addToCartMessage}
            </div>
            </div>
        </div>  
    )
}

export default Featured
