import React, { useState } from "react";
import './ClientDashboard.css'
import { useAuth } from '../contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import { db, auth } from '../firebase'


function ClientDashboard() {
    const [error, setError] = useState('')
    const { currentUser, logout } = useAuth()
    const history = useHistory()

    async function handleLogout() {
        setError("")

        try {
            await logout()
            history.push("/admin/login")
        } catch {
            setError("Failed to logout")
        }
    }

    const createTestUser = (e) => {
        e.preventDefault()

        auth.createUserWithEmailAndPassword('test1@gmail.com', 'test1234').then((user) => {
            // console.log(user)
            db
            .collection('users') // this may work for now. referencing the database may take a moment but whatever...
            .doc(user.user.uid)
            .set({
                uid: user.user.uid,
                email: user.user.email,
                role: 'admin'
            })
        })

    }

    return (
        <div>
            <div id="logged_email">
                <div id="email_admin"><strong>Email: </strong>{currentUser.email}</div>
                <button onClick={handleLogout}>Log out</button>
                {error}
            </div>
            <div id="upload_info_body">
                <h1>What would you like to do?</h1>
                <div id="upload_info_options">
                    {/* <Link className="option_buttons" to="/admin/upload_photo"><button className="button-actual" >Upload a new photo</button></Link> */}
                    <button onClick={createTestUser}>Create User Test in database</button>
                </div>
            </div>
        </div>
    )
}

export default ClientDashboard
