import React, { useState, useEffect } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import './UploadNewPhoto.css'
import { db, storage } from '../../firebase'

function UploadNewPhoto() {

    const [error, setError] = useState('')
    const { currentUser, logout } = useAuth()
    const history = useHistory()
    
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [materialOne, setMaterialOne] = useState('')
    const [materialTwo, setMaterialTwo] = useState('')
    const [materialThree, setMaterialThree] = useState('')
    const [allRatios, setAllRatios] = useState([])
    const [aspectRatioCalc, setAspectRatioCalc] = useState('')
    const [percentMarkup, setPercentMarkup] = useState(0)
    const [photoLocation, setPhotoLocation] = useState('')

    const [photo, setPhoto] = useState(null)

    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [connecting, setConnecting] = useState(true);
    const [cannotConnect, setCannotConnect] = useState(false);

    // loaded from database state

    const [fineArtPhotos, setFineArtPhotos] = useState([])
    const [earlyBirdPhotos, setEarlyBirdPhotos] = useState([])
    const [storagePhotos, setStoragePhotos] = useState([])

    const [ratios, setRatios] = useState([])
    const [materialSet, setMaterialSet] = useState([])

    // might need some kind of API for the shipping, tax and base prices w/o markup

    async function handleLogout() {
        setError("")

        try {
            await logout()
            history.push("/admin/login")
        } catch {
            setError("Failed to logout")
        }
    }

    // get Fine Art Photos
    useEffect(() => {

        const fineArtSubscriber = db
            .collection('photos')
            .doc('photos')
            .collection('Fine Art')
            .onSnapshot(snapshot => {
                setFineArtPhotos(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            })

        // cleanup function
        return () => fineArtSubscriber()
        
    }, []) // empty array means this only runs once

    // get Early Bird Photos
    useEffect(() => {

        const earlyBirdSubscriber = db
            .collection('photos')
            .doc('photos')
            .collection('Early Bird')
            .onSnapshot(snapshot => {
                setEarlyBirdPhotos(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            })

        // cleanup function
        return () => earlyBirdSubscriber()
        
    }, []) // empty array means this only runs once

    // get Storage Photos
    useEffect(() => {

        const storageSubscriber = db
            .collection('photos')
            .doc('photos')
            .collection('Storage')
            .onSnapshot(snapshot => {
                setStoragePhotos(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            })

        // cleanup function
        return () => storageSubscriber()
        
    }, []) // empty array means this only runs once

    useEffect(() => {

        const subscriber = db
            .collection('aspect_ratios')
            .doc('ratios')
            .onSnapshot(snapshot => {
                setAllRatios(snapshot.data());
                if (allRatios !== undefined) {
                    setConnecting(false)
                    // this is happenning anyway when no internet
                } else {
                    setTimeout(() => {
                        setConnecting(false);
                        setCannotConnect(true);
                    }, 2000)
                }
            })

        // cleanup function
        return () => subscriber()
        
    }, [allRatios])

    // separate use effect for populating the options
    useEffect(() => {
        
        if (!connecting) {
            if (allRatios !== undefined) {
                setRatios(Object.keys(allRatios))
                const ratioSetTemp = new Set([])
                const sizeSetTemp = new Set([])
                const materialSetTemp = new Set([])
                for (let ratio in allRatios) {
                    ratioSetTemp.add(ratio)
                    for (let size in allRatios[ratio]) {
                        sizeSetTemp.add(size)
                        for (let material in allRatios[ratio][size]) {
                            materialSetTemp.add(material)
                        }
                    }
                }
            
                setMaterialSet(Array.from(materialSetTemp))
            }
        }

    // eslint-disable-next-line
    }, [connecting])

    // check if name already exists in database
    const duplicateNameCheck = (entered_name) => {
        let value = false

        for (let photo_index = 0; photo_index < fineArtPhotos.length; photo_index++) {
            if (fineArtPhotos[photo_index].id === name) {
                alert('A photo with this name already exists, please choose a different name.')
                value = true
            }
        }

        for (let photo_index = 0; photo_index < earlyBirdPhotos.length; photo_index++) {
            if (earlyBirdPhotos[photo_index].id === name) {
                alert('A photo with this name already exists, please choose a different name.')
                value = true
            }
        }

        for (let photo_index = 0; photo_index < storagePhotos.length; photo_index++) {
            if (storagePhotos[photo_index].id === name) {
                alert('A photo with this name already exists, please choose a different name.')
                value = true
            }
        }

        return value
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        setUploading(true)
        handleAspectCalculation()

        // add to storage --- this code is still executing even though these parameters are not met
        if (photo !== null && duplicateNameCheck(name) === false){
            const uploadTask = storage.ref(`images/${photo.name}`).put(photo)
            uploadTask.on(
                "state_changed",
                snapshot => {},
                error => {
                    console.log(error);
                    setUploading(false);
                },
                () => {
                    storage
                        .ref("images")
                        .child(photo.name)
                        .getDownloadURL()
                        .then(url => {
                            console.log('url is: ', url)
                            db
                                .collection('photos')
                                .doc('photos')
                                .collection(photoLocation)
                                .doc(name)
                                .set({
                                    photo_name: name,
                                    description: description,
                                    material_one: materialOne,
                                    material_two: materialTwo,
                                    material_three: materialThree,
                                    aspectRatio: calculate(),
                                    mark_up: percentMarkup,
                                    download_url: url
                                }).then(() => {
                                    // reset form w/ refresh
                                    window.location.reload()
                                })
                        })
                }
            )
        } else {
            alert('test')
        }
    }

    const handleAspectCalculation = (e) => {

        setLoading(true)

        const image = document.getElementById('photo')
        
        const reader = new FileReader()
        
        reader.addEventListener("load", function(){
            image.setAttribute("src", this.result)
        })

        reader.readAsDataURL(photo)

    }

    const findClosestRatio = (w, h) => {
        for (let rat = 0; rat < ratios.length; rat ++) {
            let smallest = w < h ? w : h
            while (smallest > 0) {
                const values = new Int8Array(ratios[rat].split("X"))
                if (w / smallest === values[0] && h / smallest === values[1]) {
                    return ratios[rat]
                } else if (w / smallest === values[1] && h / smallest === values[0]) {
                    return ratios[rat]
                }
                smallest -= 1
                
            }
        }
        return null
    }

    const calculate = () => {

        const image = document.getElementById('photo')

        const height = image.offsetHeight
        const width = image.offsetWidth

        // calculate here!

        // this is not working EXACTLY right. Need to go back and try something else
        // the big issue here is that we subtract each value by 1. w - 1, h - 1, then both w & h - 1, the ones just change to 2
        // I need to find a way to also check values of w - 3 and w - 2 for instance
        // it may be more efficient to compare 'area' of each possible combination rather than going through each number itself?
        let new_height = height
        let new_width = width
        let count = 1
        while (count < (new_width < new_height ? new_width : new_height)) {
            
            if (findClosestRatio(new_width, new_height) != null) {
                setAspectRatioCalc(findClosestRatio(new_width, new_height))
                return findClosestRatio(new_width, new_height)
                // console.log('found one @', new_width, new_height)
                // break
            } else if (count > 0 && findClosestRatio(new_width - count, new_height) != null) {
                setAspectRatioCalc(findClosestRatio(new_width - count, new_height))
                return findClosestRatio(new_width - count, new_height)
                // console.log('found one @', new_width, 'minus', count, new_height)
                // break
            } else if (count > 0 && findClosestRatio(new_width, new_height - count) != null) {
                setAspectRatioCalc(findClosestRatio(new_width, new_height - count))
                return findClosestRatio(new_width, new_height - count)
                // console.log('found one @', new_width, new_height, 'minus', count)
                // break
            } else if (count > 0 && findClosestRatio(new_width - count, new_height - count) != null) {
                setAspectRatioCalc(findClosestRatio(new_width - count, new_height - count))
                return findClosestRatio(new_width - count, new_height - count)
                // console.log('found one @', new_width, 'minus', count, new_height, 'minus', count)
                // break
            }

            if (count + 1 === (new_width < new_height ? new_width : new_height)) {
                count = 0
                new_height -= 1
                new_width -= 1
            }

            count += 1

        }

        // stop loading and display solution
        
        setLoading(false)

    }

    return (
        <div className="upload-new-div">
            <div id="uploading_message" style={{'display': uploading ? 'block' : 'none'}}>
                <h1>Uploading...</h1>
            </div>
            <div id="cannot_connect" style={{'display': cannotConnect ? 'block' : 'none'}} >
                <div>
                    <h1>We are sorry, but there seems to be an error with your connection...</h1>
                    <p>Make sure you are connected to the internet and refresh the page.</p>
                </div>
            </div> 
            <div id="connecting_message" style={{'display': connecting ? 'block' : 'none'}}>
                <h1>Connecting to Firebase...</h1>
            </div>
            <div id="logged_email">
                <div id="email_admin"><strong>Email: </strong>{currentUser.email}</div>
                <button onClick={handleLogout}>Log out</button>
                {error}
            </div>
            <h1>Upload New Photo info</h1>
            <p>back to <a href="/admin/update_site_info">dashboard</a></p>
            <form id="submit-photo" onSubmit={handleSubmit}>
                <label>Photo Name:</label>
                <input type="text" value={name} onChange={e => setName(e.target.value)} />
                <label>Upload Photo:</label>
                <input disabled={uploading && loading ? true : false} type="file" onChange={e => setPhoto(e.target.files[0])} />
                <label>Photo Description:</label>
                <br />
                <textarea type="text" value={description} onChange={e => setDescription(e.target.value)} />
                <br />
                <label>Photo Material 1:</label>
                <br />
                <select name="material_one" defaultValue="choose material" onChange={e => setMaterialOne(e.target.value)} >
                    <option disabled>choose material</option>
                    {materialSet.map(item => {
                        return (<option key={item} value={item}>{item}</option>)
                    })}
                </select>
                <br />
                <label>Photo Material 2:</label>
                <br />
                <select name="material_two" defaultValue="choose material" onChange={e => setMaterialTwo(e.target.value)} >
                    <option disabled>choose material</option>
                    {materialSet.map(item => {
                        return (<option key={item} value={item}>{item}</option>)
                    })}
                </select>
                <br />
                <label>Photo Material 3:</label>
                <br />
                <select name="material_three" defaultValue="choose material" onChange={e => setMaterialThree(e.target.value)} >
                    <option disabled>choose material</option>
                    {materialSet.map(item => {
                        return (<option key={item} value={item}>{item}</option>)
                    })}
                </select>
                <br />
                {/* somehow will need to reference data from bay photo in order calculate size price w/ markup */}
                <br />
                <label>Percent Mark Up:</label>
                <input type="number" value={percentMarkup} onChange={e => setPercentMarkup(e.target.value)} />
                <label>Location:</label>
                <br />
                <select name="location" defaultValue="choose location" onChange={e => setPhotoLocation(e.target.value)} >
                    <option disabled>choose location</option>
                    <option>Fine Art</option>
                    <option>Early Bird</option>
                    {/* adding to early bird will push to front of an array in order to prevent confusion
                    and early bird page will only display first of array */}
                    <option>Storage</option>
                </select>
                <input type="submit" value="add photo" />
            </form>
            <div id="aspect">
                {loading ? "calculating..." : aspectRatioCalc}
            </div>
            <img alt="hidden file used to calculate the aspect ratio" id='photo' src="" />
        </div>
    )
}

export default UploadNewPhoto
