import React, { useState } from 'react';
import './PortraitPackages.css';
import GenericForm from '../GenericForm.js';

function PortraitPackages() {

    const [soloVisible, setSoloVisible] = useState(false);
    const [soliVisible, setSoliVisible] = useState(false);
    const [tuttiVisible, setTuttiVisible] = useState(false);

    const Solo_Package_Button = () => {
        setSoloVisible(true);
    }

    const Soli_Package_Button = () => {
        setSoliVisible(true);
    }

    const Tutti_Package_Button = () => {
        setTuttiVisible(true);
    }

    const RemoveModal = () => {
        setSoloVisible(false);
        setSoliVisible(false);
        setTuttiVisible(false);
    }

    return (
        <div id="portrait-packages-component">
            {soloVisible ? <div id="portrait-request-form">
                <div id="main-form-div">
                <GenericForm packageName={<h2><strong>SOLO</strong> Package</h2>} />
                </div>
                <div onClick={RemoveModal} id="modal-background"></div>
            </div> : null}
            {soliVisible ? <div id="portrait-request-form">
                <div id="main-form-div">
                <GenericForm packageName={<h2><strong>SOLI</strong> Package</h2>} />
                </div>
                <div onClick={RemoveModal} id="modal-background"></div>
            </div> : null}
            {tuttiVisible ? <div id="portrait-request-form">
                <div id="main-form-div">
                <GenericForm packageName={<h2><strong>TUTTI</strong> Package</h2>} />
                </div>
                <div onClick={RemoveModal} id="modal-background"></div>
            </div> : null}
            <div id="portrait-packages-header">
                <h1>Portrait Packages</h1>
                <p id="portrait-packages-description">all packages can be customized to meet your EXACT needs</p>
            </div>
            <div id="portrait-packages">
                <div id="portrait-package-one">
                    <h2><strong>SOLO</strong> Package</h2>
                    <div>
                        <ul className="solo-package-list">
                            <li>Google Drive gallery for purchasing</li>
                            <li>3 retouched photos with printing options</li>
                            <li>1 person</li>
                            <li>30 minute session</li>
                            <li>photos are taken in house at my studio</li>
                            <p><span style={{'fontWeight': 500}}>best for: </span><br />Business/School/Personal Headshots</p>
                        </ul>
                    </div>
                    <h1 style={{'fontWeight': 500}}>$150</h1>
                    {/* <h1 style={{'fontWeight': 500, 'color': 'green'}}>$127</h1> */}
                    <button className="select-button" onClick={Solo_Package_Button}>Select</button>
                </div>
                <div id="portrait-package-two">
                    <h2><strong>SOLI</strong> Package</h2>
                    <div>
                        <ul className="solo-package-list">
                            <li>Google Drive gallery for purchasing</li>
                            <li>8 retouched photos with printing options</li>
                            <li>1 - 4 people</li>
                            <li>1 hour session</li>
                            <li>photos are taken at the location of your choosing</li>
                            <p><span style={{'fontWeight': 500}}>best for: </span><br />Family/Newborn/Engagement shoots</p>
                        </ul>
                    </div>
                    <h1 style={{'fontWeight': 500}}>$250</h1>
                    {/* <h1 style={{'fontWeight': 500, 'color': 'green'}}>$212</h1> */}
                    <button className="select-button" onClick={Soli_Package_Button}>Select</button>
                </div>
                <div id="portrait-package-three">
                    <h2><strong>TUTTI</strong> Package</h2>
                    <div>
                        <ul className="solo-package-list">
                            <li>Google Drive gallery for purchasing</li>
                            <li>10 retouched photos with printing options</li>
                            <li>1 - 8 people</li>
                            <li>1.5 hour session</li>
                            <li>photos are taken at the location of your choosing</li>
                            <p><span style={{'fontWeight': 500}}>best for: </span><br />Family/Business shoots</p>
                        </ul>
                    </div>
                    <h1 style={{'fontWeight': 500}}>$400</h1>
                    {/* <h1 style={{'fontWeight': 500, 'color': 'green'}}>$340</h1> */}
                    <button className="select-button" onClick={Tutti_Package_Button}>Select</button>
                </div>
            </div>
            <p id="overview-link">back to <a href="/hire_me">overview</a></p>
        </div>
    )
}

export default PortraitPackages
