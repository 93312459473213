import React, { useContext, useState, useEffect } from 'react'
import { auth, db } from '../firebase'


const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const [userRole, setUserRole] = useState('')

    useEffect(() => {

        if (currentUser) {
            setLoading(true)
            db
                .collection('users')
                .doc(currentUser.uid)
                .get().then(doc => {
                    if (doc.exists) {
                        setUserRole(doc.data().role)
                        console.log(doc.data().role)
                        setLoading(false)
                    } else {
                        logout()
                    }
                })
        }

    }, [currentUser])

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logout() {
        return auth.signOut()
    }
    
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })

        return unsubscribe
    }, [])

    const value = {
        currentUser,
        userRole,
        login,
        logout
    }

    return (
        <AuthContext.Provider value={value}>
            { !loading && children }
        </AuthContext.Provider>
    )
}
