import React from 'react';
import './Order.css';

function Request({ request }) {

    return (
        <div className='order'>
            <h2 className='order-title'><strong>Request</strong></h2>
            <p><small><strong>requested on:</strong></small> {new Date(request.data.created).toString()}</p>
            <p><small><strong>requested by:</strong></small> {request.data.first_name} {request.data.last_name}</p>
            <p className='order-id'>
                <small><small><strong>Request ID:</strong></small> {request.id}</small>
            </p>
            <div className='item-description'>
                <h2>{request.data.package}</h2>
               <p>{request.data.description}</p>
            </div>
        </div>
    )
}

export default Request
