import React, { createContext, useContext, useReducer } from 'react';

export const StateContext = createContext();

export const StateProvider = ({reducer, initialstate, children}) => {
  // useEffect(() => {
  //   localStorage.setItem('cart', JSON.stringify(initialstate));
  // }, []);
  return (
    <StateContext.Provider value={useReducer(reducer, initialstate)}>
      {children}  
    </StateContext.Provider>
  )
}

export const useStateValue = () => useContext
(StateContext);

// export default StateProvider;