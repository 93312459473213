import { useElements, useStripe, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { React, useState, useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import { Link, useHistory } from 'react-router-dom';
import CheckoutProduct from '../CheckoutProduct';
import './Payment.css';
import { getSubTotal } from '../reducer';
import { useStateValue } from '../StateProvider';
import axios from '../Axios';
import { db } from '../firebase';
import firebase from 'firebase/app';

import Cross from '../checkAndCross/Cross.png';
import Check from '../checkAndCross/Check.png';

function Payment() {

    const [{ basket }, dispatch] = useStateValue();

    const history = useHistory();

    const [succeeded, setSucceeded] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState(true);

    // FOR FORM ------>>>
    const [email, setEmail] = useState('');
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [country, setCountry] = useState('USA');
    const [zipCode, setZipCode] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [shipping, setShipping] = useState('');
    const [digitalOnlyPurchase, setDigitalOnlyPurchase] = useState(false);

    // FOR VALIDATION ---->>>
    const [validatedEmail, setValidatedEmail] = useState(false);
    const [validatedFName, setValidatedFName] = useState(false);
    const [validatedLName, setValidatedLName] = useState(false);
    const [validatedAddress1, setValidatedAddress1] = useState(false);
    const [validatedState, setValidatedState] = useState(false);
    const [validatedCity, setValidatedCity] = useState(false);
    const [validatedZipCode, setValidatedZipCode] = useState(false);
    const [validatedPhoneNumber, setValidatedPhoneNumber] = useState(false);
    const [validatedCardNumber, setValidatedCardNumber] = useState(false);
    const [validatedCardExpiry, setValidatedCardExpiry] = useState(false);
    const [validatedCardCvc, setValidatedCardCvc] = useState(false);
    const [validatedCouponCode, setValidatedCouponCode] = useState(false);

    // FOR SHIPPING
    const [shippingMethod, setShippingMethod] = useState('');
    const [disableShippingMethod, setDisableShippingMethod] = useState(true)
    const [shippingMethodText, setShippingMethodText] = useState('');
    const [standardShippingPrice, setStandardShippingPrice] = useState({});
    const [twoDayShippingPrice, setTwoDayShippingPrice] = useState({});
    const [overnightShippingPrice, setOvernightShippingPrice] = useState({});
    const [shippingMethodPrice, setShippingMethodPrice] = useState(0);
    // const [shippingLoading, setShippingLoading] = useState(false);

    // COUPON CODES
    const [couponCode, setCouponCode] = useState('');
    const [discount, setDiscount] = useState(0);
    const [percentDiscount, setPercentDiscount] = useState('0%');
    const [youSavedText, setYouSavedText] = useState('');
    let coupon_codes = {
        // 'HOLIDAY2021': 0.15,
        // 'CHRISTMAS2021': 0.25,
        'FRIENDS&FAMILY': 0.45,
    }

    const formatCurrency = (number) => {
        let new_num = String(number)
        let split_num = String(number).split('.')
        if (split_num.length !== 2) {
            new_num = String(number) + '.00'
        } else if (split_num[1].length === 1) {
            new_num = String(number) + '0'
        } else if (split_num[1].length > 2) {
            if (split_num[1][0] === '0') {
                let second_num = String(Math.round((split_num[1]).slice(1,3) / 10))
                // console.log(Math.round((split_num[1]).slice(1,3) / 10))
                new_num = split_num[0] + '.0' + String(second_num[0])
                return new_num
            }
            // let second_num = String(Math.round((split_num[1]) / 10))[0] + String(Math.round((split_num[1]) / 10))[1]
            let second_num = Math.round(parseInt(String(split_num[1]).slice(0,3)) / 10)
            // console.log(second_num)
            new_num = split_num[0] + '.' + String(second_num)
        }
        return new_num
    }

    // post request to Stripe
    useEffect(() => {
        setDisabled(true)
        // console.log(disabled, processing, succeeded)

        const getClientSecret = async () => {

            const response = await axios({
                method: 'post',
                url: `/payments/create?total=${Math.round((getSubTotal(basket) + shippingMethodPrice - discount) * 100)}`
            });
            setClientSecret(response.data.clientSecret)
            // console.log(Math.round((getSubTotal(basket) + shippingMethodPrice - discount) * 100))
            setDisabled(false)
            setDisableShippingMethod(false)
        }

        getClientSecret();

    // eslint-disable-next-line
    }, [basket, discount, shippingMethod])

    const stripe = useStripe();
    const elements = useElements();

    // get shipping prices
    useEffect(() => {

        const getStandardShippingPrices = () => {
            db
                .collection("shipping_options")
                .doc("standard")
                .onSnapshot((snapshot) => {
                    setStandardShippingPrice(snapshot.data())
                })
        }
        const getTwoDayShippingPrices = () => {
            db
                .collection("shipping_options")
                .doc("two_day")
                .onSnapshot((snapshot) => {
                    setTwoDayShippingPrice(snapshot.data())
                })
        }
        const getOvernightShippingPrices = () => {
            db
                .collection("shipping_options")
                .doc("overnight")
                .onSnapshot((snapshot) => {
                    setOvernightShippingPrice(snapshot.data())
                })
        }
        getStandardShippingPrices()
        getTwoDayShippingPrices()
        getOvernightShippingPrices()
        setDisableShippingMethod(false)
    }, [])

    // shipping info
    useEffect(() => {
        const disableShippingInfo = () => {
            let addressOneField = document.querySelector('#address-one')
            let addressTwoField = document.querySelector('#address-two')
            let countryField = document.querySelector('#country')
            let zipCodeField = document.querySelector('#zip-code')
            let cityField = document.querySelector('#city')
            let stateField = document.querySelector('#state')
    
            let addressOneImage = document.querySelector('#addressOne-image')
            let addressTwoImage = document.querySelector('#addressTwo-image')
            let countryImage = document.querySelector('#country-image')
            let stateImage = document.querySelector('#state-image')
            let cityImage = document.querySelector('#city-image')
            let zipCodeImage = document.querySelector('#zipCode-image')
    
            let all_items_in_cart_are_digital = true
    
            for (let item = 0; item < basket.length; item++) {
                if (basket[item].material !== "Digital") {
                    all_items_in_cart_are_digital = false
                }
            }
            // console.log(standardShippingPrice)
            // console.log(twoDayShippingPrice)
            // console.log(overnightShippingPrice)
    
            if (all_items_in_cart_are_digital === true) {
                setDigitalOnlyPurchase(true);
                addressOneField.classList.add('hidden');
                addressTwoField.classList.add('hidden');
                countryField.classList.add('hidden');
                zipCodeField.classList.add('hidden');
                cityField.classList.add('hidden');
                stateField.classList.add('hidden');
                addressOneImage.classList.add('hidden');
                addressTwoImage.classList.add('hidden');
                countryImage.classList.add('hidden');
                zipCodeImage.classList.add('hidden');
                cityImage.classList.add('hidden');
                stateImage.classList.add('hidden');
                setAddress1('');
                addressOneImage.src = Cross;
                setValidatedAddress1(false);
                setAddress2('');
                setCity('');
                cityImage.src = Cross;
                setValidatedCity(false);
                setZipCode('');
                zipCodeImage.src = Cross;
                setValidatedZipCode(false);
                setDisableShippingMethod(true);
            } else {
                setDigitalOnlyPurchase(false);
                if (addressOneField !== null) {
                    addressOneField.classList.remove('hidden');
                    addressTwoField.classList.remove('hidden');
                    countryField.classList.remove('hidden');
                    zipCodeField.classList.remove('hidden');
                    cityField.classList.remove('hidden');
                    stateField.classList.remove('hidden');
                    addressOneImage.classList.remove('hidden');
                    addressTwoImage.classList.remove('hidden');
                    countryImage.classList.remove('hidden');
                    zipCodeImage.classList.remove('hidden');
                    cityImage.classList.remove('hidden');
                    stateImage.classList.remove('hidden');
                }
            }
        }
        return () => disableShippingInfo();
    }, [basket])

    // temp sign-in for payments...
    // also need a sign-out
    // should include another not a robot puzzle for auth
    const handleSubmit = async (e) => {
        e.preventDefault();

        // auth for guest via firebase
        firebase.auth().signInAnonymously().then(() => {
            console.log('signed in anonymously')
        }).catch((error) => {
            const errorCode = error.errorCode
            const errorMessage = error.message
            console.log(errorCode, errorMessage);
        })

        if (!error) {
            setProcessing(true);

            dispatch({
                type: 'SET_EMAIL',
                email: email
            })

            // send email to customer
            db
                .collection('emails')
                .doc('order_customer' + Date.now().toString())
                .set({
                    to: email,
                    from: 'support@alechoveyphotography.com',
                    message: {
                        subject: 'Order Confirmed!',
                        html: `Thank you for your order totaling at 
                        $${formatCurrency(getSubTotal(basket) + shippingMethodPrice - discount)}.
                        It will be processed within the next business day.`
                    },
                })

            // send email to myself
            db
            .collection('emails')
            .doc('order_owner' + Date.now().toString())
            .set({
                to: 'alechoveyphotography@gmail.com',
                from: email,
                replyTo: email,
                message: {
                    subject: 'Order',
                    html: `<p>Order from: <strong>${fName} ${lName}</strong></p>
                    <p>Order: ${basket}</p>
                    <h4>Shipping info: </h4>
                    <p>address line 1: ${address1}</p>
                    <p>address line 2: ${address2}</p>
                    <p>city: ${city}</p>
                    <p>state: ${state}</p>
                    <p>zip: ${zipCode}</p>
                    <p>method: ${shippingMethod}</p>
                    <p>options: ${shipping}</p>
                    <p>phone number: ${phoneNumber}</p>`
                },
            })    

            // const paymentMethodReq = await stripe.createPaymentMethod({
            //     type: 'card',
            //     card: elements.getElement(CardNumberElement) 
            // })
            const card = elements.getElement(CardNumberElement);
            // eslint-disable-next-line
            const result = await stripe.createToken(card);

            // eslint-disable-next-line
            const {paymentIntent, error} = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: card
                },
                // payment_amount: getSubTotal(basket) < 100 ? getCartTotal(basket) - discount : getFreeShippingTotal(basket) - discount
            }).then(({ paymentIntent }) => {
                // console.log(paymentIntent)

                db
                    // .collection('users')
                    // .doc(user?.uid)
                    .collection('orders')
                    .doc(paymentIntent.id)
                    .set({
                        basket: basket,
                        amount: paymentIntent.amount,
                        created: paymentIntent.created,
                        first_name: fName,
                        last_name: lName,
                        email: email,
                        address_one: address1,
                        address_two: address2,
                        country: country,
                        zip_code: zipCode,
                        city: city,
                        state: state,
                        phone_number: phoneNumber,
                        shipping_method: shippingMethod,
                        shipping_options: shipping,
                    }).then(() => {
                        // sign out here
                        firebase.auth().signOut().then(() => {
                            console.log('signed out')
                        })
                    })

                setSucceeded(true);
                setError(null);
                setProcessing(false);

                dispatch({
                    type: 'EMPTY_CART'
                })

                // history.replace('/orders');
                history.replace('/featured');
            });    
        } else if (error) {
            alert(`There was an error processing your payment >>> (${error})`);
        }
    }

    // VALIDATION FUNCTIONS FOR CARD INPUTS

    const handleNumberChange = (e) => {
        setValidatedCardNumber(!e.empty);
        setError(e.error ? e.error.message : '');
    }

    const handleExpiryChange = (e) => {
        setValidatedCardExpiry(!e.empty);
        setError(e.error ? e.error.message : '');
    }

    const handleCvcChange = (e) => {
        setValidatedCardCvc(!e.empty);
        setError(e.error ? e.error.message : '');
    }

    // VALIDATION FUNCTIONS FOR EACH INPUT

    const handleEmailChnage = (e) => {
        let enteredEmail = e.target.value;
        let emailNoSpace = enteredEmail.replace(/\s/g,'');

        var emailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

        let checkAndCross = document.querySelector('#email-image');

        setEmail(emailNoSpace);

        if (emailNoSpace.length === 0) {
            setValidatedEmail(false);
            checkAndCross.src = Cross;
        }

        if (emailNoSpace.match(emailFormat)) {
            setValidatedEmail(true);
            checkAndCross.src = Check;
        } else {
            setValidatedEmail(false);
            checkAndCross.src = Cross;
        }
    }

    const handleFNameChange = (e) => {
        let enteredFName = e.target.value;

        let firstLetterUpper = enteredFName.charAt(0).toUpperCase();
        let fullFName = firstLetterUpper + enteredFName.slice(1);

        let checkAndCross = document.querySelector('#fName-image');

        setFName(fullFName);
        setValidatedFName(true);
        checkAndCross.src = Check;

        if (fullFName.length === 0) {
            setValidatedFName(false);
            checkAndCross.src = Cross;
        }
    }

    const handleLNameChange = (e) => {
        let enteredLName = e.target.value;

        let firstLetterUpper = enteredLName.charAt(0).toUpperCase();
        let fullLName = firstLetterUpper + enteredLName.slice(1);

        let checkAndCross = document.querySelector('#lName-image');

        setLName(fullLName);
        setValidatedLName(true);
        checkAndCross.src = Check;

        if (fullLName.length === 0) {
            setValidatedLName(false);
            checkAndCross.src = Cross;
        }
    }

    const handlePhoneChange = (e) => {
        let enteredNumber = e.target.value;
        let splitNumber = enteredNumber.split('');

        let checkAndCross = document.querySelector('#phone-image');

        if (splitNumber.length === 10) {
            let formattedNumber = `(${splitNumber[0]}${splitNumber[1]}${splitNumber[2]}) ${splitNumber[3]}${splitNumber[4]}${splitNumber[5]}-${splitNumber[6]}${splitNumber[7]}${splitNumber[8]}${splitNumber[9]}`

            setPhoneNumber(formattedNumber);
            setValidatedPhoneNumber(true);
            checkAndCross.src = Check;
        } else {
            let unformattedNumber = enteredNumber.replace(/\D/g,'')

            setPhoneNumber(unformattedNumber);
            setValidatedPhoneNumber(false);
            checkAndCross.src = Cross;
        }
    }

    const handleAddressOneChange = (e) => {
        let enteredAddressOne = e.target.value;

        let checkAndCross = document.querySelector('#addressOne-image');

        setAddress1(enteredAddressOne);
        setValidatedAddress1(true);
        checkAndCross.src = Check;

        if (enteredAddressOne.length === 0) {
            setValidatedAddress1(false);
            checkAndCross.src = Cross;
        }
    }

    const handleStateChange = (e) => {
        let enteredState = e.target.value;

        let checkAndCross = document.querySelector('#state-image');

        setState(enteredState);
        setValidatedState(true);
        checkAndCross.src = Check;

        if (enteredState.length === 0) {
            setValidatedState(false);
            checkAndCross.src = Cross;
        }
    }

    const handleCityChange = (e) => {
        let enteredCity = e.target.value;
        let noNumbersInCity = enteredCity.replace(/\d/g,'');

        let checkAndCross = document.querySelector('#city-image');

        setCity(noNumbersInCity);
        setValidatedCity(true);
        checkAndCross.src = Check;

        if (noNumbersInCity.length === 0) {
            setValidatedCity(false);
            checkAndCross.src = Cross;
        }
    }

    const handleZipCodeChange = (e) => {
        let enteredZipCode = e.target.value;
        let numberOnlyZip = enteredZipCode.replace(/\D/g,'');

        let checkAndCross = document.querySelector('#zipCode-image');

        setZipCode(numberOnlyZip);
        setValidatedZipCode(true);
        checkAndCross.src = Check;

        if (numberOnlyZip.length < 5) {
            setValidatedZipCode(false);
            checkAndCross.src = Cross;
        }
    }

    const handleCouponCodeChange = (e) => {
        let enteredCouponCode = e.target.value;

        let checkAndCross = document.querySelector('#coupon-code-image');

        setCouponCode(enteredCouponCode);
        if (coupon_codes[enteredCouponCode]) {
            setValidatedCouponCode(true);
            checkAndCross.src = Check;
        } else {
            setValidatedCouponCode(false);
            checkAndCross.src = Cross;
        }
    }

    // const calculateDiscount = (percent, calendar_price) => {
    //     let to_subtract = (getSubTotal(basket) - calendar_price) * percent;
    //     let new_total = Math.round((getSubTotal(basket) - to_subtract + shippingMethodPrice) * 100) / 100;
    //     return new_total;
    // }

    const applyCoupon = (e) => {
        e.preventDefault();
        // check for calendar
        var price_of_calendars = 0

        for (let item = 0; item < basket.length; item++) {
            if (basket[item].title === 'Calendar') {
                price_of_calendars += basket[item].price
            }
        }

        if (validatedCouponCode === true) {
            setDiscount((getSubTotal(basket) - price_of_calendars) * coupon_codes[couponCode]);
            setYouSavedText((getSubTotal(basket) - price_of_calendars) * coupon_codes[couponCode]);
            // let new_total = calculateDiscount(coupon_codes[couponCode], price_of_calendars);
            // console.log(new_total)
            setPercentDiscount(coupon_codes[couponCode] * 100 + '%')
            // console.log(discount)
            e.disabled = true;
        }
    }

    const changeShipping = (e) => {

        var price_of_digital_photos = 0

        for (let item = 0; item < basket.length; item++) {
            // console.log(basket[item])
            if (basket[item].material === 'Digital') {
                price_of_digital_photos += basket[item].price
            }
        }

        setDisableShippingMethod(true)
        const keys = Object.keys(standardShippingPrice).sort(function(a, b){return a-b})

        if (e.target.value === "standard") {
            setShippingMethod("standard")
            for (let key = 0; key < keys.length; key++) {
                if ((getSubTotal(basket) - price_of_digital_photos) < keys[key]) {
                    setShippingMethodPrice(standardShippingPrice[keys[key]]) // these are amounts
                    setShippingMethodText(`2-5 business days ($${standardShippingPrice[keys[key]]})`)
                    break
                } else if ((getSubTotal(basket) - price_of_digital_photos) > 200) {
                    setShippingMethodPrice((getSubTotal(basket) - price_of_digital_photos) * standardShippingPrice[200] / 100) // this is a percentage NOT an amount
                    setShippingMethodText(`2-5 business days ($${formatCurrency((getSubTotal(basket) - price_of_digital_photos) * standardShippingPrice[200] / 100)})`)
                    break
                }
            }
        } else if (e.target.value === "two_day") {
            setShippingMethod("two_day")
            for (let key = 0; key < keys.length; key++) {
                if ((getSubTotal(basket) - price_of_digital_photos) < keys[key]) {
                    setShippingMethodPrice(twoDayShippingPrice[keys[key]]) // these are amounts
                    setShippingMethodText(`2 business days ($${twoDayShippingPrice[keys[key]]})`)
                    break
                } else if ((getSubTotal(basket) - price_of_digital_photos) > 200) {
                    setShippingMethodPrice((getSubTotal(basket) - price_of_digital_photos) * twoDayShippingPrice[200] / 100) // this is a percentage NOT an amount
                    setShippingMethodText(`2 business days ($${formatCurrency((getSubTotal(basket) - price_of_digital_photos) * twoDayShippingPrice[200] / 100)})`)
                    break
                }
            }
        } else if (e.target.value === "overnight") {
            setShippingMethod("overnight")
            for (let key = 0; key < keys.length; key++) {
                if ((getSubTotal(basket) - price_of_digital_photos) < keys[key]) {
                    setShippingMethodPrice(overnightShippingPrice[keys[key]]) // these are amounts
                    setShippingMethodText(`next business day ($${overnightShippingPrice[keys[key]]})`)
                    break
                } else if ((getSubTotal(basket) - price_of_digital_photos) > 200) {
                    setShippingMethodPrice((getSubTotal(basket) - price_of_digital_photos) * overnightShippingPrice[200] / 100) // this is a percentage NOT an amount
                    setShippingMethodText(`next business day ($${formatCurrency((getSubTotal(basket) - price_of_digital_photos) * overnightShippingPrice[200] / 100)})`)
                    break
                }
            }
        } 
    }
    
    // FUNCTION FOR ACTIVATED BUTTON

    useEffect(() => {

        let button = document.querySelector('#pay-now-button');

        const activatePayNowButton = () => {
            if (
            digitalOnlyPurchase === true &&
            validatedEmail === true &&
            validatedFName === true &&
            validatedLName === true &&
            validatedPhoneNumber === true &&
            validatedCardNumber === true &&
            validatedCardExpiry === true &&
            validatedCardCvc === true &&
            shippingMethod !== ''
        ) {
            setDisabled(false);
            button.classList.add('active-button');
            button.classList.remove('not-active-button');
        } else if (
            digitalOnlyPurchase === false &&
            validatedEmail === true &&
            validatedFName === true &&
            validatedLName === true &&
            validatedPhoneNumber === true &&
            validatedAddress1 === true &&
            validatedState === true &&
            validatedCity === true &&
            validatedZipCode === true &&
            validatedCardNumber === true &&
            validatedCardExpiry === true &&
            validatedCardCvc === true &&
            shippingMethod !== ''
        ) {
            setDisabled(false);
            button.classList.add('active-button');
            button.classList.remove('not-active-button');
        } else {
            setDisabled(true);
            button.classList.remove('active-button');
            button.classList.add('not-active-button');
        }
        }

        activatePayNowButton()

    }, [
        digitalOnlyPurchase, 
        validatedEmail, 
        validatedFName, 
        validatedLName, 
        validatedPhoneNumber,
        validatedAddress1,
        validatedState,
        validatedCity,
        validatedZipCode,
        validatedCardNumber,
        validatedCardExpiry,
        validatedCardCvc,
        shippingMethod
    ])

    return (
        <div className='payment-fullPage'>
            <h1>
                CHECKOUT (<Link to='/checkout'>{basket?.length} items</Link>)
            </h1>
            <form onSubmit={handleSubmit}>
                <div className='payment'>
                    <div className='payment-container'>
                        <div className='payment-section'>
                            <div className='payment-title'>
                                <h3>1. Your Email</h3>
                            </div>
                            <div className='payment-email'>
                                <div className='all-inputs'>
                                    <input 
                                    placeholder='Email' 
                                    className='payment-email-input' 
                                    type='email' 
                                    onChange={handleEmailChnage}
                                    value={email}
                                    />
                                    <div className='all-crosses'>
                                        <img id='email-image' alt='*' src={Cross} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='payment-section'>
                            <div className='payment-title'>
                                <h3>2. Shipping</h3>
                            </div>
                            <div className='shipping-details-full'>
                                {/* <div className='checkbox-container'>
                                    <input
                                    className='check-box'
                                    type='checkbox'
                                    onChange={disableShippingInfo}
                                    />
                                    <label className='label' >I only wish to purchase digital products and do not require shipping on my order.</label>
                                </div> */}
                                <div className='all-inputs'>
                                    <input
                                    placeholder='First Name' 
                                    className='shipping-details-input' 
                                    type='text' 
                                    onChange={handleFNameChange}
                                    value={fName} 
                                    />
                                    <div className='all-crosses'>
                                        <img id='fName-image' src={Cross} alt='*' />
                                    </div>
                                </div>
                                <div className='all-inputs'>
                                    <input 
                                    placeholder='Last Name' 
                                    className='shipping-details-input' 
                                    type='text' 
                                    onChange={handleLNameChange}
                                    value={lName} 
                                    />
                                    <div className='all-crosses'>
                                        <img id='lName-image' alt='*' src={Cross} />
                                    </div>
                                </div>
                                <div className='all-inputs'>
                                    <input 
                                    placeholder='Phone Number' 
                                    className='shipping-details-input' 
                                    type='tel'
                                    onChange={handlePhoneChange}
                                    value={phoneNumber}
                                    maxLength='14'
                                    />
                                    <div className='all-crosses'>
                                        <img id='phone-image' alt='*' src={Cross} />
                                    </div>
                                </div>
                                <div className='all-inputs'>
                                    <input 
                                    placeholder='Address 1' 
                                    className='shipping-details-input'
                                    id='address-one' 
                                    type='text' 
                                    onChange={handleAddressOneChange}
                                    value={address1}
                                    />
                                    <div className='all-crosses'>
                                        <img id='addressOne-image' alt='*' src={Cross} />
                                    </div>
                                </div>
                                <div className='all-inputs'>
                                    <input 
                                    placeholder='Address 2' 
                                    className='shipping-details-input'
                                    id='address-two' 
                                    type='text' 
                                    onChange={e => setAddress2(e.target.value)}
                                    value={address2}
                                    />
                                    <div className='all-crosses'>
                                        <img id='addressTwo-image' alt='*' src={Check} />
                                    </div>
                                </div>
                                <div className='all-inputs'>
                                    <select 
                                    className='shipping-details-input'
                                    id='country' 
                                    onChange={e => setCountry(e.target.value)} 
                                    >
                                        <option defaultValue value='USA'>United States of America</option>
                                    </select>
                                    <div className='all-crosses'>
                                        <img id='country-image' alt='*' src={Check} />
                                    </div>
                                </div>
                                <div className='all-inputs'>
                                    <select 
                                    placeholder='State' 
                                    className='shipping-details-input'
                                    id='state'
                                    type='text' 
                                    onChange={handleStateChange} 
                                    >
                                        <option value='' defaultValue disabled>State</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District Of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </select>
                                    <div className='all-crosses'>
                                        <img id='state-image' alt='*' src={Cross} />
                                    </div>
                                </div>
                                <div className='all-inputs'>
                                    <input 
                                    placeholder='City' 
                                    className='shipping-details-input'
                                    id='city'
                                    type='text' 
                                    onChange={handleCityChange}
                                    value={city} 
                                    />
                                    <div className='all-crosses'>
                                        <img id='city-image' alt='*' src={Cross} />
                                    </div>
                                </div>
                                <div className='all-inputs'>
                                    <input 
                                    placeholder='ZIP Code' 
                                    className='shipping-details-input'
                                    id='zip-code' 
                                    type='text'
                                    maxLength='5'
                                    onChange={handleZipCodeChange}
                                    value={zipCode} 
                                    />
                                    <div className='all-crosses'>
                                        <img id='zipCode-image' alt='*' src={Cross} />
                                    </div>
                                </div>
                                <div className='all-inputs'>
                                    <input 
                                    placeholder='Delivery Instructions' 
                                    className='shipping-details-input' 
                                    type='text' 
                                    onChange={e => setShipping(e.target.value)} 
                                    />
                                    <div className='all-crosses'>
                                        <img id='addressTwo-image' alt='*' src={Check} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='shipping-method-section'>
                            <div style={{'display': disableShippingMethod === true ? 'block' : 'none'}} className="no-shipping-required-cover">
                            </div>
                            <div className="payment-title">
                                <h3>3. Shipping Method</h3>
                            </div>
                            <div className="method-details-full">
                                {/* <div className="payment-items"> */}
                                    <div className="button-select-options-payment">
                                        <li>
                                            <input disabled={disableShippingMethod} type="radio" name="shipping" value="standard" onChange={changeShipping}/>
                                            <label htmlFor="standard">Standard</label>
                                        </li>
                                        <li>
                                            <input disabled={disableShippingMethod} type="radio" name="shipping" value="two_day" onChange={changeShipping}/>
                                            <label htmlFor="two_day">Two Day</label>
                                        </li>
                                        <li>
                                            <input disabled={disableShippingMethod} type="radio" name="shipping" value="overnight" onChange={changeShipping}/>
                                            <label htmlFor="overnight">Overnight</label>
                                        </li>
                                    </div>
                                    <div className="shipping-options-time">
                                        <p style={{'textAlign': 'center'}}>{shippingMethodText}</p>
                                    </div>
                                {/* </div> */}
                            </div>
                        </div>
                        <div className='payment-section'>
                            <div className='payment-title'>
                                <h3>4. Payment Method</h3>
                            </div>
                            <div className='payment-details-full'>
                                <div className='payment-card'>
                                    <CardNumberElement options={{
                                        placeholder: 'Card Number',
                                        style: {
                                            base: {
                                                fontSize: '20px',
                                                fontFamily: "'Exo 2', sans-serif",
                                                fontWeight: '100',
                                                color: 'black',
                                                '::placeholder': {
                                                    color: '#C6C6C6',
                                                }
                                            }
                                        }
                                    }} id='card-number' className='payment-card-input' onChange={handleNumberChange} />
                                    <CardExpiryElement options={{
                                        placeholder: 'mm / yy',
                                        style: {
                                            base: {
                                                fontSize: '20px',
                                                fontFamily: "'Exo 2', sans-serif",
                                                fontWeight: '100',
                                                color: 'black',
                                                '::placeholder': {
                                                    color: '#C6C6C6',
                                                }
                                            }
                                        }
                                    }} id='card-expiry' className='payment-card-input' onChange={handleExpiryChange} />
                                    <CardCvcElement options={{
                                        placeholder: 'cvc',
                                        style: {
                                            base: {
                                                fontSize: '20px',
                                                fontFamily: "'Exo 2', sans-serif",
                                                fontWeight: '100',
                                                color: 'black',
                                                '::placeholder': {
                                                    color: '#C6C6C6',
                                                }
                                            }
                                        }
                                    }} id='card-cvc' className='payment-card-input' onChange={handleCvcChange} />
                                </div>
                                <div id='error-message'>{error && <div>{error}</div>}</div>
                            </div>
                        </div>
                </div>
                <div className='payment-container'>
                    <div className='payment-section'>
                        <div className='payment-title'>
                            <h3>Order Summary</h3>
                        </div>
                        <div className='payment-items'>
                            {basket.map(item => (
                                <CheckoutProduct
                                key={item.id}
                                image={item.image}
                                id={item.id}
                                title={item.title}
                                price={item.price}
                                material={item.material}
                                size={item.size}
                                shipping={item.shipping}
                                buttonStyle={{'display': 'none'}}
                                />
                            ))}
                        </div>
                        <div className='payment-couponContainer'>
                            {/* <form onSubmit={applyCoupon}> */}
                                <h4>Coupon Code:</h4>
                                <div className="all-inputs">
                                    <input
                                        placeholder='Enter code here'
                                        className='coupon-code-input' 
                                        type='text' 
                                        onChange={handleCouponCodeChange}
                                        value={couponCode}
                                    />
                                    <div className='all-crosses'>
                                        <img id='coupon-code-image' alt='*' src={Cross} />
                                    </div>
                                </div>
                                <button style={{'marginBottom': '0.5rem'}} className="coupon-button" type="submit" value="Apply" onClick={applyCoupon}>Apply</button>
                            {/* </form> */}
                            <h4>Current Discount: <span style={{'color': 'rgb(45, 150, 54)'}}>{percentDiscount} off*</span></h4>
                        </div>
                        <div className='payment-priceContainer'>
                            <CurrencyFormat
                                renderText={(value) => (
                                    <h4>Sub Total: <span id='total'>{value}</span></h4>
                                )}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={getSubTotal(basket) - discount}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                            />
                            {/* <CurrencyFormat
                                renderText={(value) => (
                                    <h4>Shipping: <span id='total'>{value}</span></h4>
                                )}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={getSubTotal(basket) < 100 ? getShippingTotal(basket) : 0}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                            /> */}
                            <CurrencyFormat
                                renderText={(value) => (
                                    <h4>Shipping: <span id='total'>{value}</span></h4>
                                )}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={shippingMethodPrice}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                            />
                            <br />
                            <CurrencyFormat
                                renderText={(value) => (
                                    <h3 style={{'marginBottom': '10px'}} id="total">Order Total: <span id='total'>{value}</span></h3>
                                )}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                value={getSubTotal(basket) + shippingMethodPrice - discount}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                            />
                            {youSavedText !== '' && youSavedText !== 0 ? 
                            <CurrencyFormat
                            renderText={(value) => (
                                <h4 style={{'color': 'rgb(45, 150, 54)'}} id="total">(you save <span id='total'>{value}</span>)</h4>
                            )}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={youSavedText}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            /> : ''}
                            <hr />
                            <button id='pay-now-button' disabled={disabled || processing || succeeded} >
                                <span>{processing ? <p id='processing'>Processing...</p> : 'Buy Now'}</span>
                            </button>
                        </div>
                        <br />
                        *please be aware that coupon codes do not apply to items on sale.
                    </div>
                </div>
            </div>
            </form>
        </div>
    )
}

export default Payment
