import { React, useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import './Preview.css';
import { useStateValue } from './StateProvider';
import CurrencyFormat from 'react-currency-format';


function Preview() {

    const [size, setSize] = useState('');
    const [material, setMaterial] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [price, setPrice] = useState(null);

    const [addToCartMessage, setAddToCartMessage] = useState('');
    const [clickNumber, setClickNumber] = useState(1);

    const [materialDescription, setMaterialDescription] = useState('');

    // eslint-disable-next-line
    const [{ basket }, dispatch] = useStateValue();

    const { imageTitle } = useParams();

    let location = useLocation();
    let description = location.state.description;
    let image = location.state.image;
    let title = location.state.title;
    let id = location.state.id;

    // Sizes
    let size1 = location.state.size1;
    let size2 = location.state.size2;
    let size3 = location.state.size3;

    // Materials
    let material1 = location.state.material1
    let material2 = location.state.material2
    let material3 = location.state.material3
    let digital = location.state.digital;

    // Pricing
    let price_Digital = location.state.price_Digital;
    // Material 1
    let price_M1_S1 = location.state.price_M1_S1;
    let price_M1_S2 = location.state.price_M1_S2;
    let price_M1_S3 = location.state.price_M1_S3;
    // Material 2
    let price_M2_S1 = location.state.price_M2_S1;
    let price_M2_S2 = location.state.price_M2_S2;
    let price_M2_S3 = location.state.price_M2_S3;
    // Material 3
    let price_M3_S1 = location.state.price_M3_S1;
    let price_M3_S2 = location.state.price_M3_S2;
    let price_M3_S3 = location.state.price_M3_S3;

    // Dropdown Styling
    // let size1_Style = location.state.size1_Style;
    // let size2_Style = location.state.style2_Style;
    // let size3_Style = location.state.style3_Style;
    // let material1_Style = location.state.material1_Style;
    // let material2_Style = location.state.material2_Style;
    // let material3_Style = location.state.material3_Style;
    // let digital_Style = location.state.digital_Style;


    let style = {
        backgroundColor: "#F1F3F4"
    }

    const priceSizeChange = (e) => {

        var button = document.querySelector('#basket-button');
        let buttonChangeOnSize = e.target.value;

        setSize(e.target.value);

        if (buttonChangeOnSize !== "") {
            if (button.classList === 'basket-button') {
                return;
            } else {
            button.classList.remove('error');
            button.classList.add('basket-button');
            }
        }
    }

    const priceMaterialChange = (e) => {

        var button = document.querySelector('#basket-button');
        let buttonChangeOnSize = e.target.value;

        setMaterial(e.target.value);

        if (e.target.value === 'Digital') {
            const size1Button = document.getElementById(size1)
            const size2Button = document.getElementById(size2)
            const size3Button = document.getElementById(size3)

            size1Button.checked = false
            size2Button.checked = false
            size3Button.checked = false
        }

        if (buttonChangeOnSize !== "") {
            if (button.classList === 'basket-button') {
                return;
            } else {
            button.classList.remove('error');
            button.classList.add('basket-button');
            }
        }

    }

    useEffect(() => {

        if (material === material1 && size === size1) {
            setPrice(price_M1_S1);
        }
        if (material === material1 && size === size2) {
            setPrice(price_M1_S2);
        }
        if (material === material1 && size === size3) {
            setPrice(price_M1_S3);
        }
        if (material === material2 && size === size1) {
            setPrice(price_M2_S1);
        }
        if (material === material2 && size === size2) {
            setPrice(price_M2_S2);
        }
        if (material === material2 && size === size3) {
            setPrice(price_M2_S3);
        }
        if (material === material3 && size === size1) {
            setPrice(price_M3_S1);
        }
        if (material === material3 && size === size2) {
            setPrice(price_M3_S2);
        }
        if (material === material3 && size === size3) {
            setPrice(price_M3_S3);
        }
        if (material === digital) {
            setPrice(price_Digital);
            setSize("Full Resolution");
            setIsDisabled(true);
        } else if (material !== digital) {
            setIsDisabled(false);
        }

        if (material === 'Canvas') {
            setMaterialDescription('Canvas prints are printed on high quality canvas paper and wrapped around a 2" frame. The best quality canvas prints are usually bigger because they have a tendancy to distort finer details. Canvas prints do not come with a frame.')
        } else if (material === 'Metal') {
            setMaterialDescription('Metal prints are printed on glossy aluminum and come with a small nail mount in the back. The mount gives the appearance that the print is suspended from the wall. Metal prints tend to be best at highlighting details and making prints appear vibrant. Metal prints are great if you are looking for a modern look.')
        } else if (material === 'Paper') {
            setMaterialDescription('Paper prints are the cheapest prints but are the most versetile. Printed on high quality glossy photo paper, paper prints can be mounted and framed in whatever way you choose. Paper prints are shipped with extra care in order to avoid bending or creasing.')
        } else if (material === 'Digital') {
            setMaterialDescription('Digital prints are the most versatile. Use them as a desktop background or have them printed on your favorite material as many times as you like. After ordering a digital print, a full resolution file will be sent directly to your email. Please contact myself if you plan to use these digital files for commercial use as there will be separate pricing for licensing agreements.')
        } else {
            setMaterialDescription('');
        }
        
    }, [
        material, 
        size,
        material1,
        material2,
        material3,
        size1,
        size2,
        size3,
        digital,
        price_M1_S1,
        price_M1_S2,
        price_M1_S3,
        price_M2_S1,
        price_M2_S2,
        price_M2_S3,
        price_M3_S1,
        price_M3_S2,
        price_M3_S3,
        price_Digital,
        ]
    )

    const addToBasket = () => {

        var button = document.querySelector('#basket-button');
        // let message = document.querySelector('.add-to-cart-message');

        if (size === "" || material === "") {
            // alert('test');
            button.classList.add('error');
            button.classList.remove('basket-button');
        } else if (size === "Full Resolution" && material !== 'Digital') {
            button.classList.add('error');
            button.classList.remove('basket-button');
        } else if (size !== "" && material !== "") {
            dispatch({
                type: 'ADD_TO_BASKET',
                item: {
                    id: Date.now() + id,
                    title: title,
                    image: image,
                    material: material,
                    size: size,
                    price: price,
                }
            })
            setClickNumber(clickNumber + 1);
            if (clickNumber === 1) {
                setAddToCartMessage(`You have added ${clickNumber} ${title} photo to your cart!`);
            } else {
                setAddToCartMessage(`You have added ${clickNumber} ${title} photos to your cart!`);
            }
        }
    }

    // console.log(material, size)

    //add prices to change based on size selection
    //go to each page component and add as props (price?)

    //might be good to include sizes ^^^

    return (
        <div className='preview'>
            {/* <Link to="/featured">back</Link> */}
            <div id="featured-item-page">
            <div id="preview-full" >
                <div id="preview-column-one" style={style} >
                    <h1 className="column-header" >{imageTitle}</h1>
                    <img id="preview-img"
                    src={image}
                    alt='*' 
                    />
                    <p id="description" ><i>{description}</i></p>
                </div>
                <div id="preview-column-two" style={style} >
                    <h1 className="column-header">Purchase Options</h1>
                    {/* <p id="price">${price}</p> */}
                    {price === null ? <p id='price'>$</p> : <CurrencyFormat
                    renderText={(value) => (
                        <span id="price">{value}</span>
                    )}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={parseFloat(price)}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    />}
                    {/* <select defaultValue='select a material' onChange={priceMaterialChange} name='materials' id='materials' className="dropdown" >
                        <option disabled>select a material</option>
                        <option style={material1_Style} value={material1}>{material1}</option>
                        <option style={material2_Style} value={material2}>{material2}</option>
                        <option style={material3_Style} value={material3}>{material3}</option>
                        <option style={digital_Style} value={digital}>{digital}</option>
                    </select> */}
                    <p className="choose-text">Choose your material:</p>
                    <div className="button-select-options">
                        <li>
                            <input type='radio' name='material' value={material1} id={material1} onChange={priceMaterialChange}/>
                            <label for={material1}>{material1}</label>
                        </li>
                        <li>
                            <input type='radio' name='material' value={material2} id={material2} onChange={priceMaterialChange}/>
                            <label for={material2}>{material2}</label>
                        </li>
                        <li>
                            <input type='radio' name='material' value={material3} id={material3} onChange={priceMaterialChange}/>
                            <label for={material3}>{material3}</label>
                        </li>
                        <li>
                            <input type='radio' name='material' value={digital} id={digital} onChange={priceMaterialChange}/>
                            <label for={digital}>{digital}</label>
                        </li>
                    </div>
                    {/* <select disabled={isDisabled} defaultValue='select a size' onChange={priceSizeChange} name='sizes' id='sizes' className="dropdown" >
                        <option disabled>select a size</option>
                        <option style={size1_Style} value={size1}>{size1}</option>
                        <option style={size2_Style} value={size2}>{size2}</option>
                        <option style={size3_Style} value={size3}>{size3}</option>
                    </select> */}
                    <p className="choose-text">Choose your size:</p>
                    <div id="button-select-options-two" className="button-select-options">
                        <li>
                            <input disabled={isDisabled} type='radio' name='size' value={size1} id={size1} onChange={priceSizeChange}/>
                            <label for={size1}>{size1}</label>
                        </li>
                        <li>
                            <input disabled={isDisabled} type='radio' name='size' value={size2} id={size2} onChange={priceSizeChange}/>
                            <label for={size2}>{size2}</label>
                        </li>
                        <li>
                            <input disabled={isDisabled} type='radio' name='size' value={size3} id={size3} onChange={priceSizeChange}/>
                            <label for={size3}>{size3}</label>
                        </li>
                        <li id="placeholder">
                            <label></label>
                        </li>
                    </div>
                    <br />
                    <div id="print-info">
                        <p>{materialDescription}</p>
                    </div>
                    <button id="basket-button" className="basket-button" onClick={addToBasket} >Add to Cart</button>
                </div>
            </div>
            <div className='add-to-cart-message'>
                {addToCartMessage}
            </div>
            </div>
        </div>
    )
}

export default Preview
