import React from 'react';
import moment from 'moment';
import '../../Order.css';
import CheckoutProduct from '../../CheckoutProduct';
import CurrencyFormat from 'react-currency-format';

function Order({ order }) {

    const hiddenStyle = {
        visibility: 'hidden'
    }

    const fullStyle = {
        justifyContent: 'center',
        // display: 'flex'
    }

    return (
        <div className='order'>
            <h2 className='order-title'><strong>Order</strong></h2>
            <p><small><strong>purchased on:</strong></small> {moment.unix(order.data.created).format('MMMM Do YYYY, h:mma')}</p>
            <p><small><strong>purchased by:</strong></small> {order.data.first_name} {order.data.last_name}</p>
            <p className='order-id'>
                <small><small><strong>Order ID:</strong></small> {order.id}</small>
            </p>
            <div className='item-description'>
                {order.data.basket?.map(item => (
                    <CheckoutProduct
                        id={item.id}
                        image={item.image}
                        title={item.title}
                        size={item.size}
                        material={item.material}
                        price={item.price}
                        buttonStyle={hiddenStyle}
                        fullStyle={fullStyle}
                    />
                ))}
                <CurrencyFormat
                    renderText={(value) => (
                        <h3 className='order-total'>Order Total: {value}</h3>
                    )}
                    decimalScale={2}
                    value={order.data.amount / 100}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                />
            </div>
        </div>
    )
}

export default Order
