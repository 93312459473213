import './App.css';
import { useStateValue } from './StateProvider';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './Header';
import Featured from './mainPages/Featured';
import FineArt from './mainPages/FineArt';
import Checkout from './mainPages/Checkout';
import Preview from './Preview';
import ScrollToTop from './ScrollToTop';
import Contact from './mainPages/Contact';
import Payment from './mainPages/Payment';
import Orders from './Orders';
import HireMe from './mainPages/HireMe';
import EarlyBird from './mainPages/EarlyBird';
import Landing from './mainPages/Landing';
import AdminLogin from './adminPages/general/AdminLogin';
import UpdateSiteInfo from './adminPages/general/UpdateSiteInfo';
import ClientLogin from './clientPages/ClientLogin';
import ClientDashboard from './clientPages/ClientDashboard';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PortraitPackages from './photoPackages/PortraitPackages';
import EventPackages from './photoPackages/EventPackages';
import WeddingPackages from './photoPackages/WeddingPackages';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRouteOwner from './adminPages/general/PrivateRouteOwner'
import UploadNewPhoto from './adminPages/photos/UploadNewPhoto';
import UploadNewItem from './adminPages/items/UploadNewItem';
import EditPhoto from './adminPages/photos/EditPhoto';
import EditItem from './adminPages/items/EditItem';
import AllOrders from './adminPages/general/AllOrders';
import AllInquiries from './adminPages/general/AllInquiries';
import AllRequests from './adminPages/general/AllRequests';
import PrivateRouteClient from './adminPages/general/PrivateRouteClient';
import AddUser from './adminPages/general/AddUser';

const promise = loadStripe('pk_live_51II3huGyirxHBdi8yePniZD8r6BFnw2t6ChJEUv1tuKUovZgI2T9NyQDbolTOjBsQxHJJOR0Al1pyUTLdUFjbWGM00NiAbvVHo');


function App() {

  // eslint-disable-next-line
  const [{ basket }, dispatch] = useStateValue();

  if (basket === null) {
    localStorage.setItem('cart', JSON.stringify([]));
  }

  return (
    <Router>
      <div className="app">
        <ScrollToTop />
          {/* Admin Paths */}
          <AuthProvider>
            <Switch>
              <PrivateRouteOwner path='/admin/update_site_info' component={UpdateSiteInfo} />
              <PrivateRouteOwner path='/admin/upload_photo' component={UploadNewPhoto} />
              <PrivateRouteOwner path='/admin/edit_photo' component={EditPhoto} />
              <PrivateRouteOwner path='/admin/upload_item' component={UploadNewItem} />
              <PrivateRouteOwner path='/admin/edit_item' component={EditItem} />
              <PrivateRouteOwner path='/admin/add_user' component={AddUser} />
              <PrivateRouteOwner path='/admin/all_orders' component={AllOrders} />
              <PrivateRouteOwner path='/admin/all_inquiries' component={AllInquiries} />
              <PrivateRouteOwner path='/admin/all_requests' component={AllRequests} />
              <Route path='/admin/login' component={AdminLogin} />
              <PrivateRouteClient path='/client/dashboard' component={ClientDashboard} />
              <Route path='/client/login' component={ClientLogin} />
              <Route exact path={`/featured/:imageTitle`} render={() => <><Header /><Preview /></>} />
              <Route exact path={`/fine_art/:imageTitle`} render={() => <><Header /><Preview /></>} />
              <Route path='/copyrightcontract'>
                <h1>This will include a written contract (eventually)</h1>
                <p>Back to <a href='/checkout' >checkout</a></p>
              </Route>
              <Route path='/hire_me/portrait_packages' render={() => <><PortraitPackages /></>} />
              <Route path='/hire_me/event_packages' render={() => <><EventPackages /></>} />
              <Route path='/hire_me/wedding_packages' render={() => <><WeddingPackages /></>} />
              <Route path='/orders' render={() => <><Orders /></>} />
              <Route path='/checkout' render={() => <><Header /><Checkout /></>} />
              <Route path='/payment' render={() => <><Elements options={{ fonts: [ {cssSrc: 'https://fonts.googleapis.com/css2?family=Exo+2:wght@100&display=swap'} ]}} stripe={promise}><Payment /></Elements></>} />
              <Route path='/fine_art' render={() => <><Header /><FineArt /></>} />
              <Route path='/hire_me' render={() => <><Header /><HireMe /></>} />
              <Route path='/contact' render={() => <><Header /><Contact /></>} />
              <Route path='/featured' render={() => <><Header /><Featured /></>} />
              <Route path='/early_bird_print' render={() => <><Header /><EarlyBird /></>} />
              <Route exact path='/' render={() => <><Landing /></>} />
              <Route path='*'>
                <h1>Oops! This page does not exist!</h1>
              </Route>
            </Switch>
          </AuthProvider>
          {/* <Route path='/featured/:id'>
            <h1>Oops! This page does not exist!</h1>
          </Route>
          <Route path='/gallery/:id'>
            <h1>Oops! This page does not exist!</h1>
          </Route>  */}
      </div>
    </Router>  
  );
}

export default App;
