import React, { useState, useRef } from 'react';
import './GenericForm.css';
import { GrSend } from 'react-icons/gr';
import { db } from './firebase';
import firebase from 'firebase/app';
import Recaptcha from 'react-google-recaptcha';

function GenericForm({packageName}) {

    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [email, setEmail] = useState('');
    const [comments, setComments] = useState('');

    const [formSubmit, setFormSubmit] = useState('');
    const [isVerified, setIsVerified] = useState(false);

    const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

    const reRef = useRef()

    //styleOnEnter

    window.addEventListener('input', function() {
        var fNameStyle = document.querySelector("#first-name-input-generic");
        var lNameStyle = document.querySelector("#last-name-input-generic");
        var emailStyle = document.querySelector("#email-input-generic");
        var commentStyle = document.querySelector("#comment-input-generic");

        
        if (fNameStyle == null) {
            return;
        }
        if (lNameStyle == null) {
            return;
        }
        if (emailStyle == null) {
            return;
        }
        if (commentStyle == null) {
            return;
        }


        if (fName.length > 0) {
            fNameStyle.classList.add("styleOnEnter");
            fNameStyle.classList.remove("styleBeforeEnter");
            fNameStyle.classList.remove('styleOnError');
        } else if (fName.length === 0) {
            fNameStyle.classList.add("styleBeforeEnter");
            fNameStyle.classList.remove("styleOnEnter");
        }
        if (lName.length > 0) {
            lNameStyle.classList.add("styleOnEnter");
            lNameStyle.classList.remove("styleBeforeEnter");
            lNameStyle.classList.remove('styleOnError');
        } else if (lName.length === 0) {
            lNameStyle.classList.add("styleBeforeEnter");
            lNameStyle.classList.remove("styleOnEnter");
        }
        if (email.length > 0) {
            emailStyle.classList.add("styleOnEnter");
            emailStyle.classList.remove("styleBeforeEnter");
            emailStyle.classList.remove('styleOnError');
        } else if (email.length === 0) {
            emailStyle.classList.add("styleBeforeEnter");
            emailStyle.classList.remove("styleOnEnter");
        }
        if (comments.length > 0) {
            commentStyle.classList.add("styleOnEnter");
            commentStyle.classList.remove("styleBeforeEnter");
            commentStyle.classList.remove('styleOnError');
        } else if (comments.length === 0) {
            commentStyle.classList.add("styleBeforeEnter");
            commentStyle.classList.remove("styleOnEnter");
        }
    });

    const submitValidation = (event) => {
        event.preventDefault();

        // auth for guest via firebase
        firebase.auth().signInAnonymously().then(() => {
            console.log('signed in anonymously')
        }).catch((error) => {
            const errorCode = error.errorCode
            const errorMessage = error.message
            console.log(errorCode, errorMessage);
        }).then(() => {
        var fNameStyle = document.querySelector("#first-name-input-generic");
        var lNameStyle = document.querySelector("#last-name-input-generic");
        var emailStyle = document.querySelector("#email-input-generic");
        var commentStyle = document.querySelector("#comment-input-generic");
        var requiredFName = document.querySelector("#required-fname");
        var requiredLName = document.querySelector("#required-lname");
        var requiredEmail = document.querySelector("#required-email");
        var requiredComment = document.querySelector("#required-comments");

        
        if (comments.length === 0) {
            commentStyle.classList.add('styleOnError');
            requiredComment.classList.remove('visibility-hidden');
            requiredComment.classList.add('visibility-visibile');
            event.preventDefault();
        } else if (comments.length > 0) {
            requiredComment.classList.remove('visibility-visible');
            requiredComment.classList.add('visibility-hidden');
            event.preventDefault();
        }
        if (email.length === 0) {
            emailStyle.classList.add('styleOnError');
            requiredEmail.classList.remove('visibility-hidden');
            requiredEmail.classList.add('visibility-visible');
            event.preventDefault();
        } else if (email.length > 0) {
            requiredEmail.classList.remove('visibility-visible');
            requiredEmail.classList.add('visibility-hidden');
            event.preventDefault();
        }
        if (email.indexOf('@') < 0) {
            emailStyle.classList.add('styleOnError');
            requiredEmail.classList.remove('visibility-hidden');
            requiredEmail.classList.add('visibility-visible');
            event.preventDefault();
        } else if (email.length > 0) {
            requiredEmail.classList.remove('visibility-visible');
            requiredEmail.classList.add('visibility-hidden');
            event.preventDefault();
        }
        if (lName.length === 0) {
            lNameStyle.classList.add('styleOnError');
            requiredLName.classList.remove('visibility-hidden');
            requiredLName.classList.add('visibility-visible');
            event.preventDefault();
        } else if (lName.length > 0) {
            requiredLName.classList.remove('visibility-visible');
            requiredLName.classList.add('visibility-hidden');
            event.preventDefault();
        }
        if (fName.length === 0) {
            fNameStyle.classList.add('styleOnError');
            requiredFName.classList.remove('visibility-hidden');
            requiredFName.classList.add('visibility-visible');
            event.preventDefault();
        } else if (fName.length > 0) {
            requiredFName.classList.remove('visibility-visible');
            requiredFName.classList.add('visibility-hidden');
            event.preventDefault();
        }
        if (fName.length > 0 &&
            lName.length > 0 &&
            email.length > 0 &&
            comments.length > 0   
        ) {
                db
                    .collection('event-requests')
                    .doc(Date.now().toString())
                    .set({
                        first_name: fName,
                        last_name: lName,
                        email: email,
                        package: packageName.props.children[0].props.children,
                        description: comments,
                        created: Date.now(),
                    }).then(() => {
                        setFormSubmit('Request Sent!');
                        // sign out here
                        firebase.auth().signOut().then(() => {
                            console.log('signed out')
                        }).catch((error) => {
                            const errorCode = error.errorCode
                            const errorMessage = error.message
                            console.log(errorCode, errorMessage);
                        })
                        reRef.current.reset()
                    }).catch((error) => {
                        setFormSubmit(error.message);
                        firebase.auth().signOut().then(() => {
                            console.log('signed out')
                        }).catch((error) => {
                            const errorCode = error.errorCode
                            const errorMessage = error.message
                            console.log(errorCode, errorMessage);
                        })
                        reRef.current.reset()
                    });

                    // send email to customer
                    db
                    .collection('emails')
                    .doc('event-customer' + Date.now().toString())
                    .set({
                        to: email,
                        from: 'support@alechoveyphotography.com',
                        message: {
                            subject: 'Event Request Received!',
                            html: 'Thank you for reaching out to me! I should respond to your request within 1 business day.'
                        },
                    })

                    // send email to myself
                    db
                    .collection('emails')
                    .doc('event-owner' + Date.now().toString())
                    .set({
                        to: 'alechoveyphotography@gmail.com',
                        from: email,
                        replyTo: email,
                        message: {
                            subject: 'Event Request',
                            html: `<p>Message from: <strong>${fName} ${lName}</strong></p><p>Package: <strong>${packageName.props.children[0].props.children}</strong></p><p>${comments}</p>`
                        },
                    })

                setFName('');
                setLName('');
                setEmail('');
                setComments('');
            }
        })
        }

        const handleVerification = (response) => {
            setIsVerified(true)
        }

    return (
        <div id="generic-form-div">
            <form id="generic-form">
                <div id="package-name">{packageName}</div>
                <div id="names-generic">
                    <div id="first-name-generic">
                        <label>First Name: </label><br />
                        <input className="styleBeforeEnter" id="first-name-input-generic" onChange={e => setFName(e.target.value)} value={fName} type="text" /><br />
                        <div id='required'>
                            <small className="visibility-hidden" id="required-fname" >*Enter your first name</small><br />
                        </div>
                    </div>
                    <div id="last-name-generic">
                        <label>Last Name: </label><br />
                        <input className="styleBeforeEnter" id="last-name-input-generic" onChange={e => setLName(e.target.value)} value={lName} type="text" /><br />
                        <div id='required'>
                            <small className="visibility-hidden" id="required-lname" >*Enter your last name</small><br />
                        </div>
                    </div>
                </div>
                <div id="email-generic">
                    <label>Your Email: </label><br />
                    <input className="styleBeforeEnter" id="email-input-generic" onChange={e => setEmail(e.target.value)} value={email} type="text" />
                    <div id='required'>
                        <small className="visibility-hidden" id="required-email" >*Enter a valid email address</small><br />
                    </div>
                </div>
                <br />
                <div id="comments-generic">
                    <label>Describe your event: </label><br />
                    <textarea className="styleBeforeEnter" id="comment-input-generic" onChange={e => setComments(e.target.value)} value={comments} type="text-box" />
                    <div id='required'>
                        <small className="visibility-hidden" id="required-comments" >*Please enter your message</small>
                    </div>
                </div>
                <Recaptcha
                sitekey={recaptchaKey}
                onChange={handleVerification}
                className="recaptcha-contact"
                ref={reRef}
                />
                <div id="generic-submit">
                    <button disabled={isVerified ? false : true} id="generic-form-button" onClick={submitValidation} type="submit" for="contact-form" >Send Request {<GrSend size={10} />}</button>
                </div>
            </form>
            <div id="package-form-submit" className='submit-popup'>
                {formSubmit}
            </div>
        </div>
    )
}

export default GenericForm
