import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://us-central1-alec-hovey-photography.cloudfunctions.net/api',
})

export default instance;

// FireBase deploy --->
// https://us-central1-alec-hovey-photography.cloudfunctions.net/api

// LocalHost deploy --->
// http://localhost:5001/alec-hovey-photography/us-central1/api