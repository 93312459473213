import React from 'react';
import './Photo.css';
// import { useStateValue } from './StateProvider';


function Photo({image, borderColor, delayTime}) {

    // const [{ basket }, dispatch] = useStateValue();

    // console.log('My Cart: ', basket);

    const imageStyle = {
        backgroundImage: `linear-gradient(to bottom right, ${ borderColor }, #FFFFFF00)`,
        animationDelay: delayTime
    }

    // const addToBasket = () => {
    //     dispatch({
    //         type: 'ADD_TO_BASKET',
    //         image: image,
    //         text: 'this is a test',
    //     })
    // }

    return (
        <div className='photo-click' >
            <img 
            className="photo" 
            id="photo-one" 
            src={ image } 
            alt="clickable link"
            style={ imageStyle }
            />
        </div>
    )
}

export default Photo
