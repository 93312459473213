const localData = localStorage.getItem('cart');
const cartItems = JSON.parse(localData);

export const initialstate = {
    basket: cartItems,
    email: null
};

export const getFreeShippingTotal = (basket) =>
    basket?.reduce((amount, item) => item.price + amount, 0);

export const getShippingTotal = (basket) =>
    basket?.reduce((amount, item) => item.shipping + amount, 0);

export const getCartTotal = (basket) => 
    basket?.reduce((amount, item) => (item.price + item.shipping) + amount, 0);

export const getSubTotal = (basket) => 
    basket?.reduce((amount, item) => item.price + amount, 0);

const reducer = (state, action) => {

    switch(action.type) {
        case 'ADD_TO_BASKET':

            localStorage.setItem('cart', JSON.stringify([...state.basket, action.item]));

            const localData = localStorage.getItem('cart');
            const cartItems = JSON.parse(localData);

            return {
                ...state,
                basket: cartItems,
            };

        case 'EMPTY_CART':

            localStorage.setItem('cart', JSON.stringify([]));

            return {
                ...state,
                basket: []
            }         

        case 'REMOVE_FROM_CART':
            const index = state.basket.findIndex(
                (basketItem) => basketItem.id === action.id
            );
            let newBasket = [...state.basket];
            
            if (index != null) {
                newBasket.splice(index, 1);

            } else {
                console.warn(
                    `Can't remove product (id: ${action.id}) because it is not in the cart.`
                )
            }

            localStorage.setItem('cart', JSON.stringify(newBasket));

            return {
                ...state, 
                basket: newBasket
            }

        case 'SET_EMAIL':
            return {
                ...state,
                email: action.email
            }    

            default:
                return state;
    }
};

export default reducer;